import DayItem from './day-item.jsx';
import useTripStoreV4, { useTripStoreSelectorsV4 } from '@/stores/useTripStore_v4.js';
import { useEffect, useRef, useState } from 'react';
import ArrangeDaysModal from '@/components/home-v4/modals/arrange-days-modal.jsx';
import { DragDropContext } from 'react-beautiful-dnd';
import { cloneDeep, trim } from 'lodash-es';
import { Button, Input, Modal, Spin } from 'antd';
import notification from '@/utils/notification.jsx';
import AddPlaceModal from '@/components/home-v4/modals/add-place-modal.jsx';
import EditDurationModal from '@/components/home-v4/modals/edit-duration-modal.jsx';
import AddCityModal from '@/components/home-v4/modals/add-city-modal.jsx';
import emitter from '@/utils/emitter.js';
import { ZZEmittedEvent, ZZUploadType } from '@/utils/const.js';
import { swapAttraction, updateDay } from '@/apis/trips_v4.js';
import { LoadingOutlined } from '@ant-design/icons';
import styles from './itinerary-panel.module.scss';
import IconFlight from '../../../assets/svgs/icon_flight_line.svg?react';
import IconHotel from '../../../assets/svgs/icon_hotel_line.svg?react';
import IconArrange from '../../../assets/svgs/icon_arrange.svg?react';
import IconAdd from '../../../assets/svgs/icon_add.svg?react';
import { useGlobalStoreSelectors } from '@/stores/useGlobalStore.js';
import { useResponsive } from 'ahooks';
import toast from '@/utils/toast.js';
import { useGenerateStoreSelectors } from '@/stores/useGenerateStore.js';

function ItineraryPanel({ className }) {
  const [modal, contextHolder] = Modal.useModal();
  const itinerary = useTripStoreSelectorsV4.use.itinerary();
  const updateItinerary = useTripStoreSelectorsV4.use.updateItinerary();
  const updateAttractionDuration = useTripStoreSelectorsV4.use.updateAttractionDuration();
  const updateDayTitle = useTripStoreSelectorsV4.use.updateDayTitle();
  const isGenerating = useGenerateStoreSelectors.use.isGenerating();
  const insertDay = useTripStoreSelectorsV4.use.insertDay();
  const [isShowRearrange, setIsShowRearrange] = useState(false);
  const [isShowAddPlace, setIsShowAddPlace] = useState(false);
  const [isShowEditDuration, setIsShowEditDuration] = useState(false);
  const [operateDay, setOperateDay] = useState(null);
  const [operateDayIndex, setOperateDayIndex] = useState(0);
  const [operateAttractionIndex, setOperateAttractionIndex] = useState(0);
  const [operateAttraction, setOperateAttraction] = useState(null);
  const [isShowDestination, setIsShowDestination] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDay, setSelectedDay] = useState('All');
  const { md } = useResponsive();
  const state = useRef({
    dayTitle: ''
  });

  useEffect(() => {
    initEvent();
    return () => {
      emitter.off(ZZEmittedEvent.editStayCity);
      emitter.off(ZZEmittedEvent.scrollToDay);
    };
  }, []);

  const initEvent = () => {
    emitter.on(ZZEmittedEvent.editStayCity, params => {
      console.log('params--->1', params);
      const hotel = params.hotel;
      if (hotel.place_type === 'place_to_stay') {
        setOperateDay(params.day);
        setOperateDayIndex(params.dayIndex);
        setIsShowDestination(true);
      }
    });

    emitter.on(ZZEmittedEvent.scrollToDay, dayIndex => {
      const t = setTimeout(() => {
        clearTimeout(t);
        const id = `itinerary-day-${dayIndex}`;
        console.log('element id-->', id);
        const element = document.getElementById(id);
        console.log('element-->', element);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 600);
    });
  };

  const onRearrangeDay = () => {
    if (isGenerating) {
      toast.info('The itinerary is generating...');
      return;
    }
    setIsShowRearrange(true);
  };

  const onDragEnd = async result => {
    try {
      const { source, destination } = result;
      if (!destination || (destination.index === source.index && destination.droppableId === source.droppableId)) {
        return;
      }
      const itinerary = cloneDeep(useTripStoreV4.getState().itinerary);
      let days = itinerary.daily_itineraries;
      let sourceDay = days.find(o => o.id === source.droppableId);
      let sourceDayIndex = days.indexOf(sourceDay);
      const [remove] = sourceDay.places.splice(source.index, 1);
      let targetDay = days.find(o => o.id === destination.droppableId);
      let targetDayIndex = days.indexOf(targetDay);
      targetDay.places.splice(destination.index, 0, remove);
      const { sessionId, itineraryId } = useTripStoreV4.getState();
      updateItinerary(itinerary, false);
      setIsLoading(true);
      const data = await swapAttraction({
        session_id: sessionId,
        itinerary_id: itineraryId,
        day_index_from: sourceDayIndex + 1,
        day_index_to: targetDayIndex + 1,
        attraction_index_from: source.index + 1,
        attraction_index_to: destination.index + 1
      });
      updateItinerary(data, false);
    } catch (e) {
      console.log('e', e);
    } finally {
      setIsLoading(false);
    }
  };

  const onAddPlaceModal = day => {
    if (isGenerating) {
      toast.info('The itinerary is generating...');
      return;
    }
    setOperateDay(day);
    setOperateDayIndex(itinerary.daily_itineraries.indexOf(day));
    setIsShowAddPlace(true);
  };

  const onAddPlaceSuccess = () => {
    setIsShowAddPlace(false);
  };

  const onEditDuration = options => {
    if (isGenerating) {
      toast.info('The itinerary is generating...');
      return;
    }
    console.log('options-->', options);
    setOperateDayIndex(options.dayIndex);
    setOperateAttractionIndex(options.attractionIndex);
    setOperateAttraction(options.attraction);
    setIsShowEditDuration(true);
  };

  const onFinishDuration = duration => {
    operateAttraction.duration_minutes = duration;
    updateAttractionDuration(operateDay, operateAttraction);
  };

  const onEditDayTitle = async day => {
    if (isGenerating) {
      toast.info('The itinerary is generating...');
      return;
    }
    state.current.dayTitle = day.introduction;
    await modal.confirm({
      centered: true,
      title: 'Edit day title',
      width: 400,
      content: (
        <>
          <Input.TextArea
            rows={3}
            onChange={({ target }) => (state.current.dayTitle = target.value)}
            defaultValue={day.introduction}
            placeholder="Please input day title."
            style={{
              marginTop: 8,
              fontSize: 13,
              height: '100%',
              resize: 'none'
            }}
          />
        </>
      ),
      onOk: close => {
        if (!trim(state.current.dayTitle)) {
          return;
        }
        updateDayTitle(day, trim(state.current.dayTitle));
        notification.success({
          message: 'Edit day title success.'
        });
        close();
      },
      onCancel: () => {
        console.log('cancel');
      }
    });
  };

  const onModifyStayCity = async options => {
    if (isGenerating) {
      toast.info('The itinerary is generating...');
      return;
    }
    console.log('options-->', options);
    const { sessionId, itineraryId } = useTripStoreV4.getState();
    const data = await updateDay({
      session_id: sessionId,
      itinerary_id: itineraryId,
      day_index: operateDayIndex + 1,
      daily_content: {
        place_to_stay: {
          name: options.name
        }
      }
    });
    notification.success({
      message: 'Modify stay city success.'
    });
    updateItinerary(data, false);
  };

  const onAddDay = () => {
    if (isGenerating) {
      toast.info('The itinerary is generating...');
      return;
    }
    modal.confirm({
      centered: true,
      title: 'Add Confirm',
      content: 'Do you want to add a new day?',
      width: md ? 400 : '90vw',
      className: 'confirmModal',
      icon: null,
      closable: true,
      okText: 'Confirm',
      onOk: async close => {
        // setIsLoading(true);
        await insertDay(itinerary.daily_itineraries.length, 'after');
        // setIsLoading(false);
        close();
      },
      onCancel: () => {
        console.log('cancel');
      }
    });
  };

  const getDailyItineraries = () => {
    if (selectedDay == 'All') {
      return itinerary?.daily_itineraries || [];
    } else {
      return [itinerary?.daily_itineraries[selectedDay]];
    }
  };

  return (
    <div className={`pb-2 relative ${className} -mt-2`}>
      <Spin wrapperClassName={styles.spinWrapper2} indicator={<LoadingOutlined style={{ fontSize: 24 }} />} spinning={isLoading}>
        <div>
          <DayTabs onRearrangeDay={onRearrangeDay} onAddDay={onAddDay} selectedDay={selectedDay} setSelectedDay={setSelectedDay} />
          <DragDropContext onDragEnd={onDragEnd}>
            {getDailyItineraries().map((day, index) => {
              return (
                <DayItem
                  key={day.id}
                  index={index}
                  day={day}
                  lastDay={itinerary?.daily_itineraries[index - 1]}
                  onAddPlace={onAddPlaceModal}
                  onEditDuration={onEditDuration}
                  onEditDayTitle={onEditDayTitle}
                />
              );
            })}
          </DragDropContext>
        </div>
      </Spin>
      <ArrangeDaysModal
        open={isShowRearrange}
        onCancel={() => {
          setIsShowRearrange(false);
        }}
      />
      <AddPlaceModal open={isShowAddPlace} onCancel={() => setIsShowAddPlace(false)} onOk={onAddPlaceSuccess} dayIndex={operateDayIndex} />
      <EditDurationModal
        open={isShowEditDuration}
        dayIndex={operateDayIndex}
        attractionIndex={operateAttractionIndex}
        attraction={operateAttraction}
        onOk={onFinishDuration}
        onCancel={() => setIsShowEditDuration(false)}
      />
      <AddCityModal isPromiseSubmit={true} open={isShowDestination} onCancel={() => setIsShowDestination(false)} onSelectedPlace={onModifyStayCity} />
      {contextHolder}
    </div>
  );
}

function DayTabs({ selectedDay, setSelectedDay, onRearrangeDay, onAddDay }) {
  const { md } = useResponsive();
  const itinerary = useTripStoreSelectorsV4.use.itinerary();
  const isReadOnly = useTripStoreSelectorsV4.use.isReadOnly();
  const toggleIsShowUpload = useGlobalStoreSelectors.use.toggleIsShowUpload();
  const onAddFlight = () => {
    toggleIsShowUpload(true, ZZUploadType.flight);
  };

  const onAddHotel = () => {
    toggleIsShowUpload(true, ZZUploadType.hotel);
  };

  return (
    <div className={`flex flex-wrap items-center justify-between px-2 md:px-4 mb-3`}>
      <span className={`flex items-center flex-wrap ${styles.dayTabs} my-2 mr-6`}>
        <span
          className={`f-center ${styles.tab} ${selectedDay == 'All' ? styles.active : ''}`}
          onClick={() => {
            setSelectedDay('All');
          }}
        >
          All
        </span>
        {itinerary?.daily_itineraries.map(day => {
          return (
            <span
              key={day.dayIndex}
              className={`f-center ${styles.tab} ${selectedDay == day.dayIndex ? styles.active : ''}`}
              onClick={() => {
                setSelectedDay(day.dayIndex);
              }}
            >
              Day {day.dayIndex + 1}
            </span>
          );
        })}
        <span className={`f-center ${styles.tab} ${styles.circle} ${isReadOnly ? '!hidden' : ''}`} onClick={onAddDay}>
          <IconAdd />
        </span>
        <span className={`f-center ${styles.tab} ${styles.circle} ${isReadOnly ? '!hidden' : ''}`} onClick={onRearrangeDay}>
          <IconArrange />
        </span>
      </span>
      <span className={`flex items-center flex-wrap my-2 ${isReadOnly ? '!hidden' : ''}`}>
        <Button icon={<IconFlight />} onClick={onAddFlight} size={md ? '' : 'small'}>
          Add Flight
        </Button>
        <Button icon={<IconHotel />} onClick={onAddHotel} className={'mx-3'} size={md ? '' : 'small'}>
          Add Hotel
        </Button>
      </span>
    </div>
  );
}

export default ItineraryPanel;

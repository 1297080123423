import { Modal, Form } from 'antd';
import styles from './add-place-modal.module.scss';
import GoogleMapSearchBox from '@/components/commons/GoolgeMapSearchBox/index.jsx';
import { SearchOutlined } from '@ant-design/icons';
import toast from '@/utils/toast.js';
import { useRef, useState } from 'react';
import { useResponsive } from 'ahooks';
import usePlaceV4 from '@/hooks/usePlace_v4.jsx';
import { searchPlace } from '@/apis/trips_v4.js';
import useTripStore_v4 from '@/stores/useTripStore_v4.js';

function AddPlaceModal({ targetPlace, onOk, dayIndex, onCancel, ...props }) {
  const { md } = useResponsive();
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState(targetPlace || '');
  const ref = useRef(null);
  const { onAddPlace } = usePlaceV4();
  const state = useRef({
    place: null
  });

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      if (state.current.place == null) {
        toast.info('Please search and select attraction!');
        return;
      }
      const { sessionId } = useTripStore_v4.getState();
      console.log('state.current.place', state.current.place);
      const place_type = state.current.place?.types.includes('lodging') ? 'hotel' : 'attraction';
      const place = await searchPlace({
        session_id: sessionId,
        place_id: state.current.place?.place_id,
        place_type
      });
      await onAddPlace(null, place, {
        isShowLoading: false,
        dayIndex: (dayIndex || 0) + 1
      });
      onOk && onOk();
    } catch (e) {
      console.log('ee--', e);
    } finally {
      setIsLoading(false);
    }
  };

  const onPlaceChange = async (_, option) => {
    try {
      console.log('option', option);
      state.current.place = option.extra;
    } catch (e) {
      console.log('e-->', e);
    }
  };

  const onAfterOpenChange = open => {
    if (open) {
      if (targetPlace) {
        console.log('triggerSearch');
        ref?.current && ref.current.triggerSearch(targetPlace);
        ref?.current && ref?.current.focus();
      }
    } else {
      state.current.place = null;
    }
  };

  const onSearch = value => {
    setSearchValue(value);
  };

  return (
    <Modal
      className={styles.addModal}
      {...props}
      centered
      width={md ? 450 : '100%'}
      onOk={onSubmit}
      okText="Submit"
      onCancel={onCancel}
      title="Add Attraction"
      afterOpenChange={onAfterOpenChange}
      zIndex={2000}
      destroyOnClose
      okButtonProps={{
        loading: isLoading
      }}
    >
      <Form
        style={{ marginTop: 20 }}
        name="basic"
        labelCol={{
          span: 5
        }}
        wrapperCol={{
          span: 18
        }}
        initialValues={{
          city: undefined
        }}
      >
        <Form.Item label="Attraction" name="attraction">
          <GoogleMapSearchBox
            defaultOpen
            ref={ref}
            searchValue={searchValue}
            onSearch={onSearch}
            showSearch
            allowClear
            onChange={onPlaceChange}
            types={['premise', 'natural_feature', 'park', 'point_of_interest']}
            style={{ width: md ? 320 : '100%' }}
            placeholder="Add attraction"
            suffixIcon={<SearchOutlined style={{ fontSize: 18 }} />}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default AddPlaceModal;

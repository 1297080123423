import { Col, Popover, Row } from 'antd';
import ChatPanel from '@/components/home-v4/chat-panel.jsx';
import useTripStoreV4, { useTripStoreSelectorsV4 } from '@/stores/useTripStore_v4.js';
import { useCallback, useEffect, useState } from 'react';
import cache from '@/utils/cache.js';
import { addMyTrip } from '@/apis/trips.js';
import { useLocation, useSearchParams } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import InfoDrawer from '@/components/home-v4/drawers/info-drawer.jsx';
import { getTripDestination } from '@/utils/trip.js';
import ImagesDrawer from '@/components/home-v4/drawers/images-drawer.jsx';
import UploadModal from '@/components/home-v4/modals/upload-modal.jsx';
import BookingModal from '@/components/home-v4/modals/booking-modal.jsx';
import { useGlobalStoreSelectors } from '@/stores/useGlobalStore.js';
import { updateItineraryIntent } from '@/apis/trips_v4.js';
import WhenPanel from '@/components/home-v4/when-panel.jsx';
import usePopover from '@/hooks/usePopover.js';
import notification from '@/utils/notification.jsx';
import emitter from '@/utils/emitter.js';
import { ZZEmittedEvent } from '@/utils/const.js';
import YoutubePlayerModal from '@/components/home-v4/modals/youtube-player-modal.jsx';
import { useResponsive } from 'ahooks';
import { tagEvent } from '@/utils/ga.js';
import useUserStore from '@/stores/useUserStore.js';
import { useGenerateStoreSelectors } from '@/stores/useGenerateStore.js';
import Page from '@/components/page/index.jsx';
import LeftPanel from '@/components/home-v4/left-panel.jsx';
import toast from '@/utils/toast.js';
import styles from './chat.module.scss';

function Chat() {
  const [isLoading, setIsLoading] = useState(true);
  const [params] = useSearchParams();
  const location = useLocation();
  const setSessionId = useTripStoreSelectorsV4.use.setSessionId();
  const fetchItineraryAndIntent = useTripStoreSelectorsV4.use.fetchItineraryAndIntent();
  const resetItineraryAndIntent = useTripStoreSelectorsV4.use.resetItineraryAndIntent();
  const setIsReadOnly = useTripStoreSelectorsV4.use.setIsReadOnly();
  const reset = useGenerateStoreSelectors.use.reset();
  const isShowPlaceInfo = useGlobalStoreSelectors.use.isShowPlaceInfo();
  const toggleIsShowPlaceInfo = useGlobalStoreSelectors.use.toggleIsShowPlaceInfo();
  const [isShowPhotos, setIsShowPhotos] = useState(false);
  const [allImages, setAllImages] = useState([]);
  const isShowUpload = useGlobalStoreSelectors.use.isShowUpload();
  const isShowBooking = useGlobalStoreSelectors.use.isShowBooking();
  const [isShowPlayer, setIsShowPlayer] = useState(false);
  const [operateSource, setOperateSource] = useState({});
  const { md } = useResponsive();

  useEffect(() => {
    initData();
    initEvent();
    fetchData();
    return () => {
      emitter.off(ZZEmittedEvent.showPlayer);
      emitter.off(ZZEmittedEvent.loginSuccess);
      emitter.off(ZZEmittedEvent.addMyTrip);
      emitter.off(ZZEmittedEvent.showTrip);
      setIsReadOnly(false);
      reset();
    };
  }, []);

  const initData = () => {
    cache.setLastChooseDay(1);
    resetItineraryAndIntent();
    reset();
    if (location.pathname === '/share') {
      setIsReadOnly(true);
    }
    tagEvent('event', 'conversion', {
      send_to: 'AW-16706051350/MCjJCP-A1dMZEJa6iJ4-',
      value: 1.0,
      currency: 'USD'
    });
  };

  const initEvent = useCallback(() => {
    emitter.on(ZZEmittedEvent.showPlayer, onShowPlayer);
    emitter.on(ZZEmittedEvent.loginSuccess, () => {
      onAddTrip();
    });
    emitter.on(ZZEmittedEvent.addMyTrip, () => {
      onAddTrip();
    });
  }, []);

  const onAddTrip = async () => {
    const { isLogin } = useUserStore.getState();
    if (isLogin) {
      const { itinerary, sessionId } = useTripStoreV4.getState();
      await addMyTrip({
        session_id: sessionId,
        user_id: cache.getUser().user_id,
        extra_info: {
          title: itinerary?.title || '--'
        }
      });
      notification.success({
        message: `${itinerary?.title} added to your trips`
      });
    }
  };

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      const session_id = params.get('session_id');
      const itineraryId = params.get('id') || params.get('itinerary_id');
      if (itineraryId) {
        setSessionId(session_id);
        await fetchItineraryAndIntent();
      }
    } catch (e) {
      console.log('e->', e);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const onShowPlayer = useCallback(video => {
    // if (md) {
    setOperateSource(video);
    setIsShowPlayer(true);
    // } else {
    //   window.open(video.url);
    // }
  }, []);

  const onShowPhotos = images => {
    setIsShowPhotos(true);
    setAllImages(images);
  };

  return (
    <Page className="relative w-full border-t">
      {isLoading ? (
        <div className={'h-full f-center'}>
          <LoadingOutlined style={{ fontSize: 36, color: 'black' }} spin />
        </div>
      ) : (
        <Row className={'h-full box-border'}>
          {md && <TravelIntent />}
          <Col span={24} className={'h-full !flex flex-col'}>
            {/*<div className={'p-3 bg-white flex items-center justify-between z-[999]'}>{md ? <span /> : <HamburgerMenu />}</div>*/}
            <Row className={'h-0 flex-grow'}>
              {md && (
                <Col span={14} className={'h-full z-0 border-r border-r-[#F3F4F6]'}>
                  <LeftPanel />
                </Col>
              )}
              <Col span={md ? 10 : 24} className={'h-full'}>
                <ChatPanel />
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      <InfoDrawer
        open={isShowPlaceInfo}
        onClose={() => {
          toggleIsShowPlaceInfo(false, null);
        }}
        onShowPhotos={onShowPhotos}
      />
      <ImagesDrawer images={allImages} open={isShowPhotos} title={'Show all photos'} onClose={() => setIsShowPhotos(false)} />
      <UploadModal open={isShowUpload} />
      <BookingModal open={isShowBooking} />
      <YoutubePlayerModal open={isShowPlayer} onOk={() => setIsShowPlayer(false)} video={operateSource} />
    </Page>
  );
}

function TravelIntent(props) {
  const pickedIntent = useTripStoreSelectorsV4.use.pickedIntent();
  const itinerary = useTripStoreSelectorsV4.use.itinerary();
  const fetchItineraryAndIntent = useTripStoreSelectorsV4.use.fetchItineraryAndIntent();
  const [whenOpen, setWhenOpen] = usePopover();
  const [isLoading, setIsLoading] = useState(false);
  const isReadOnly = useTripStoreSelectorsV4.use.isReadOnly();
  const isGenerating = useGenerateStoreSelectors.use.isGenerating();

  const onChangeWhen = async values => {
    try {
      if (isReadOnly) {
        toast.info('Please save as your own itinerary first.');
        return;
      }
      setIsLoading(true);
      setWhenOpen(false);
      console.log('values-->', values);
      const { sessionId, itineraryId } = useTripStoreV4.getState();
      await updateItineraryIntent({
        session_id: sessionId,
        duration_in_days: values.duration_in_days,
        id: itineraryId
      });
      notification.success({
        message: `The itinerary has been changed to ${values.duration_in_days} days.`
      });
      await fetchItineraryAndIntent();
    } catch (e) {
      console.log('ee->', e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div {...props} className={`z-20 fixed top-3.5 left-1/2 -translate-x-1/2 ${props.className}`}>
      {!!pickedIntent?.destination && (
        <div className={`flex items-center text-[13px] border rounded-[20px] font-medium ${styles.intent}`}>
          {pickedIntent?.destination_places?.length <= 1 ? (
            <span className={'py-2 min-w-[90px] px-3 f-center'}>{getTripDestination(pickedIntent)}</span>
          ) : (
            <Popover
              zIndex={2000}
              placement="bottom"
              content={
                <ul>
                  {pickedIntent.destination_places.map(item => (
                    <li key={item.place_id} className={'mb-1'}>
                      {item.name}
                    </li>
                  ))}
                </ul>
              }
            >
              <div className={'py-2 min-w-[90px] max-w-[200px] ellipsis-1 px-3 f-center cursor-pointer'}>
                {pickedIntent?.destination_places?.length} {pickedIntent?.destination_places?.length > 1 ? 'destinations' : 'destination'}
              </div>
            </Popover>
          )}
          <Popover
            zIndex={2000}
            placement="bottom"
            content={<WhenPanel onChange={onChangeWhen} initialDays={itinerary?.daily_itineraries?.length} />}
            trigger="click"
            getPopupContainer={trigger => trigger.parentNode}
            open={whenOpen}
            onOpenChange={setWhenOpen}
          >
            <span className={'cursor-pointer py-1 min-w-[90px] px-3 f-center border-l'}>
              {(isLoading || isGenerating) && <LoadingOutlined style={{ fontSize: 16, marginRight: 8 }} />}
              <span>
                {itinerary?.daily_itineraries?.length || '--'} {itinerary?.daily_itineraries?.length > 1 ? 'days' : 'day'}
              </span>
            </span>
          </Popover>
          {pickedIntent?.companions?.number_of_adults && (
            <span className={'py-1 min-w-[90px] px-3 f-center border-l'}>
              {pickedIntent?.companions?.number_of_adults + pickedIntent?.companions?.number_of_kids} travelers
            </span>
          )}
        </div>
      )}
    </div>
  );
}

export default Chat;

import { useCallback, useState } from 'react';
import styles from './trip-intent.module.scss';
import { Button, Divider, Input, Popover } from 'antd';
import { CloseCircleOutlined, SearchOutlined } from '@ant-design/icons';
import WhenPanel from '@/components/home-v4/when-panel.jsx';
import WhoPanel from '@/components/home-v4/who-panel.jsx';
import { get, isEmpty } from 'lodash-es';
import toast from '@/utils/toast.js';
import usePopover from '@/hooks/usePopover.js';
import useTripStoreV4, { useTripStoreSelectorsV4 } from '@/stores/useTripStore_v4.js';
import cache from '@/utils/cache.js';
import { useNavigate } from 'react-router-dom';
import { useResponsive, useToggle } from 'ahooks';
import DestinationSearchBox from '@/components/commons/DestinationSearchBox/index.jsx';
import GenerateFromModal from '@/components/home-v4/modals/generate-from-modal.jsx';
import { logEvent } from '@/utils/ga.js';
import IconYoutube from '../../assets/svgs/icon_youtube.svg?react';
import IconReddit from '../../assets/svgs/icon_reddit.svg?react';

function TripIntent() {
  const setPickedDestinations = useTripStoreSelectorsV4.use.setPickedDestinations();
  const setPickedWho = useTripStoreSelectorsV4.use.setPickedWho();
  const setPickedWhen = useTripStoreSelectorsV4.use.setPickedWhen();
  const [destinations, setDestinations] = useState([]);
  const [when, setWhen] = useState(null);
  const [who, setWho] = useState(null);
  const [whenOpen, setWhenOpen] = usePopover();
  const [whoOpen, setWhoOpen] = usePopover();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { md } = useResponsive();
  const [isShowFromUrl, { setLeft, setRight }] = useToggle();

  const onSelectDestination = (v, option) => {
    logEvent({
      name: 'intent_destinations_select'
    });
    setDestinations(prevState => [...prevState, option]);
  };

  const onDeselectDestination = value => {
    setDestinations(prevState => prevState.filter(o => o.objectID !== value));
  };

  const onChangeWhen = values => {
    logEvent({
      name: 'intent_when_select'
    });
    setWhen(values);
    setWhenOpen(false);
  };

  const onChangeWho = values => {
    logEvent({
      name: 'intent_who_select'
    });
    setWho(values);
    setWhoOpen(false);
  };

  const onClearWhen = useCallback(() => {
    setWhen(null);
  }, []);

  const onClearWho = useCallback(() => {
    setWho(null);
  }, []);

  const onSearch = async () => {
    try {
      console.log('destinations-->', destinations);
      console.log('when-->', when);
      console.log('who-->', who);
      if (isEmpty(destinations)) {
        toast.info('Please select destinations.');
        return;
      }
      logEvent({
        name: 'intent_submit'
      });
      setIsLoading(true);
      setPickedDestinations(destinations);
      setPickedWho(who);
      setPickedWhen(when);
      const { sessionId } = useTripStoreV4.getState();
      const intent = {
        origin: 'San Francisco', //FIXME:暂时写死的
        origin_place: {
          name: 'San Francisco',
          city: 'San Francisco',
          region: 'California',
          country: 'United States',
          formatted_address: 'San Francisco, CA, USA',
          google_place_id: 'ChIJIQBpAG2ahYAR_6128GcTUEo',
          latitude: 37.7749295,
          longitude: -122.4194155
        },
        destination: destinations.map(o => o.name).join(';'),
        destination_places: destinations.map(item => {
          return {
            name: item.name,
            city: item.city,
            region: item.region,
            country: item.country,
            formatted_address: get(item, 'geocodes.0.formatted_address', ''),
            google_place_id: get(item, 'geocodes.0.google_place_id', ''),
            latitude: get(item, 'geocodes.0.latlng.lat', ''),
            longitude: get(item, 'geocodes.0.latlng.lng', '')
          };
        }),
        start_date: when?.start_date,
        duration_in_days: when?.duration_in_days,
        companions: who
      };
      cache.setGenerateParams({
        travel_intent: intent
      });
      let url = `/chat?session_id=${sessionId}&type=generate`;
      const params = new URLSearchParams(window.location.search);
      params.forEach((val, key) => {
        url += `&${key}=${val}`;
      });
      navigate(url);
    } catch (e) {
      console.log('e', e);
    } finally {
      setIsLoading(false);
    }
  };

  const onShowGenerate = () => {
    logEvent({
      name: 'generate_plan_enter'
    });
    setRight();
  };

  return (
    <>
      <div
        className={`relative flex w-[90%] flex-col items-stretch md:items-center md:flex-row md:w-[1200px] bg-[#fff] pt-4 md:pt-2 pb-4 pl-4 md:pl-10 pr-4 z-10 rounded-xl md:rounded-[51px] border ${styles.intent} z-20`}
      >
        <div className={`${styles.item} flex flex-col flex-1`}>
          <span className={'font-medium mb-2'}>Where</span>
          <DestinationSearchBox
            mode="multiple"
            placeholder="Search Destinations"
            onSelect={onSelectDestination}
            onDeselect={onDeselectDestination}
            maxTagCount="responsive"
          />
        </div>
        <Divider type="vertical" className={'!h-[60%] self-end !mx-6 !invisible md:!visible'} />
        <div className={`${styles.item} flex flex-col flex-1 mt-5 md:mt-0`}>
          <span className={'font-medium mb-2'}>
            <span>When</span>
            <span className={'font-medium text-[12px] text-[#888] ml-2'}>(Optional)</span>
          </span>
          <Popover
            arrow={md}
            placement="bottom"
            content={<WhenPanel onChange={onChangeWhen} />}
            trigger="click"
            getPopupContainer={trigger => trigger.parentNode}
            open={whenOpen}
            onOpenChange={setWhenOpen}
          >
            <Input
              readOnly
              placeholder="Select Dates"
              className={'!cursor-pointer'}
              value={when?.desc}
              suffix={
                when?.desc && (
                  <CloseCircleOutlined
                    onClick={onClearWhen}
                    style={{
                      color: 'rgba(0,0,0,.25)'
                    }}
                  />
                )
              }
            />
          </Popover>
        </div>
        <Divider type="vertical" className={'!h-[60%] self-end !mx-6 !invisible md:!visible'} />
        <div className={`${styles.item} flex flex-col flex-1 mt-5 md:mt-0`}>
          <span className={'font-medium mb-2 flex items-center'}>
            <span>Who</span>
            <span className={'font-medium text-[12px] text-[#888] ml-2'}>(Optional)</span>
          </span>
          <Popover
            arrow={md}
            placement="bottom"
            content={<WhoPanel onChange={onChangeWho} />}
            trigger="click"
            getPopupContainer={trigger => trigger.parentNode}
            open={whoOpen}
            onOpenChange={setWhoOpen}
          >
            <Input
              readOnly
              placeholder="Add guests"
              className={'!cursor-pointer'}
              value={who?.desc}
              suffix={
                who?.desc && (
                  <CloseCircleOutlined
                    onClick={onClearWho}
                    style={{
                      color: 'rgba(0,0,0,.25)'
                    }}
                  />
                )
              }
            />
          </Popover>
        </div>
        <Button
          type="primary"
          size="large"
          loading={isLoading}
          icon={
            <SearchOutlined
              style={{
                fontSize: 20,
                fontWeight: 'bold'
              }}
            />
          }
          shape={md ? 'circle' : 'round'}
          className={'mt-5 md:ml-5 !w-20 !md:w-10 !h-10 md:!w-14 md:!h-14 shrink-0 self-center'}
          onClick={onSearch}
        />
      </div>
      <div className={'f-center w-[100vw] md:w-[30vw]'}>
        <Divider style={{ borderColor: '#FFFFFF1F' }} className={`!text-white !text-[12px] !my-6`}>
          Or
        </Divider>
      </div>
      <span onClick={onShowGenerate} className={'text-[16px] border-[#E5E7EB] bg-[#fff] f-center py-4 px-6 rounded-[32px] cursor-pointer'}>
        <span className={`mr-3 ${styles.btn}`}>Generate Plan from URL</span>
        <IconYoutube />
        <IconReddit className={'mx-2'} />
        {/*<IconTiktok />*/}
      </span>
      <GenerateFromModal open={isShowFromUrl} onCancel={setLeft} />
    </>
  );
}

export default TripIntent;

import { useEffect, useState } from 'react';
import { Select, Spin } from 'antd';

let service = null;
let token = null;

function HotelSearchBox(props) {
  const [options, setOptions] = useState([]);
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    await window.google.maps.importLibrary('places');
    service = new window.google.maps.places.AutocompleteService();
    token = new window.google.maps.places.AutocompleteSessionToken();
  };

  const onSearch = async text => {
    try {
      if (!service) {
        await init();
      }
      setFetching(true);
      service.getPlacePredictions(
        {
          input: text,
          language: 'en',
          // types: ['lodging', 'point_of_interest', 'tourist_attraction'],
          sessionToken: token
        },
        (predictions, status) => {
          console.log('predictions', predictions);
          setFetching(false);
          if (status === 'OK') {
            setOptions(
              predictions.map(item => {
                return {
                  value: item.place_id,
                  label: item.structured_formatting.main_text
                };
              })
            );
          }
        }
      );
    } catch (e) {
      console.log('e', e);
    }
  };

  const onSelect = (_, option) => {
    console.log('item-->', option);
    if (props.onChange) {
      props.onChange(option);
    }
  };

  return (
    <Select
      {...props}
      showSearch
      options={options}
      onSearch={onSearch}
      filterOption={false}
      onSelect={onSelect}
      notFoundContent={fetching ? <Spin size="small" /> : null}
    />
  );
}

export default HotelSearchBox;

import { EnvironmentOutlined } from '@ant-design/icons';

function PlaceMarker({ place, color }) {
  const getIcon = () => {
    if (place?.place_type === 'hotel') {
      return (
        <svg className="icon text-[20px]" aria-hidden="true">
          <use xlinkHref="#icon-hotel-line"></use>
        </svg>
      );
    } else if (place?.place_type === 'restaurant') {
      return (
        <svg className="icon text-[20px]" aria-hidden="true">
          <use xlinkHref="#icon-restaurant-15"></use>
        </svg>
      );
    }
    return <EnvironmentOutlined className={'text-[20px]'} />;
  };

  return (
    <div className={'f-center relative'}>
      <span className={`w-8 h-8 rounded-full f-center shrink-0 ${color ? 'text-white' : 'text-black'}`} style={{ backgroundColor: color || 'white' }}>
        {getIcon()}
      </span>
      <span
        className={`pointer-events-none absolute w-[10em] text-[13px] font-medium leading-[1.05] ${
          color ? 'text-white' : 'text-black'
        }  top-1/2 -translate-y-1/2 left-[calc(100%+theme(width[0.5]))]`}
      >
        <span className={`box-decoration-clone px-1 rounded-sm`} style={{ backgroundColor: color || 'white' }}>
          {place.name}
        </span>
      </span>
    </div>
  );
}

export default PlaceMarker;

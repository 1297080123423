import { useState } from 'react';
import { Button } from 'antd';
import styles from './who-panel.module.scss';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';

const list = ['Solo', 'Couple', 'Family', 'Friends'];

function WhoPanel({ onChange }) {
  const [type, setType] = useState('Couple');
  const [petCount, setPetCount] = useState(0);
  const [teensCount, setTeensCount] = useState(0);
  const [adultsCount, setAdultsCount] = useState(0);
  const [childrenCount, setChildrenCount] = useState(0);

  const onDone = () => {
    let desc = type;
    if (type !== 'Solo' && type !== 'Couple') {
      desc += `, ${adultsCount} Adult${adultsCount > 1 ? 's' : ''}`;
      const d = teensCount + childrenCount;
      if (d > 0) {
        desc += `, ${d} Child${d > 1 ? 'ren' : ''}`;
      }
    }
    onChange({
      companion_type: type,
      number_of_adults: adultsCount,
      number_of_kids: teensCount + childrenCount,
      age_of_kids: generateAgeArray(teensCount, childrenCount),
      has_pet: petCount > 0,
      has_senior: false,
      desc
    });
  };

  const generateAgeArray = (teensCount, childrenCount) => {
    const childrenMedian = 6; // 儿童的中位数年龄（0-12 岁的中位数）
    const teensMedian = 15; // 青少年的中位数年龄（13-18 岁的中位数）
    // 根据 childrenCount 填充儿童的中位数
    const childrenAges = Array(childrenCount).fill(childrenMedian);
    // 根据 teensCount 填充青少年的中位数
    const teensAges = Array(teensCount).fill(teensMedian);
    // 合并两个数组
    return childrenAges.concat(teensAges);
  };

  return (
    <div className={`bg-white w-[90vw] md:w-[380px] flex flex-col items-center pt-2 ${styles.who}`}>
      <div className={`${styles.tabs} w-[80%]`}>
        {list.map(item => {
          return (
            <span key={item} className={`${styles.tab} ${type == item ? styles.active : ''}`} onClick={() => setType(item)}>
              {item}
            </span>
          );
        })}
      </div>
      <div className={'flex items-center justify-between w-full px-3 mt-5'}>
        <span className={'flex flex-col'}>
          <span className={styles.title}>Pets</span>
          <span className={styles.desc}>Bringing an assistance animal?</span>
        </span>
        <span className={'flex items-center'}>
          <Button size={'small'} icon={<MinusOutlined />} shape={'circle'} onClick={() => setPetCount(petCount - 1)} disabled={petCount <= 0} />
          <span className={styles.count}>{petCount}</span>
          <Button size={'small'} icon={<PlusOutlined />} shape={'circle'} onClick={() => setPetCount(petCount + 1)} />
        </span>
      </div>
      {['Family', 'Friends'].includes(type) && (
        <div className={'flex items-center justify-between w-full px-3 mt-5'}>
          <span className={'flex flex-col'}>
            <span className={styles.title}>Adults</span>
            <span className={styles.desc}>Ages 18 or above</span>
          </span>
          <span className={'flex items-center'}>
            <Button
              size={'small'}
              icon={<MinusOutlined />}
              shape={'circle'}
              onClick={() => setAdultsCount(adultsCount - 1)}
              disabled={adultsCount <= 0}
            />
            <span className={styles.count}>{adultsCount}</span>
            <Button size={'small'} icon={<PlusOutlined />} shape={'circle'} onClick={() => setAdultsCount(adultsCount + 1)} />
          </span>
        </div>
      )}
      {['Family'].includes(type) && (
        <div className={'flex items-center justify-between w-full px-3 mt-5'}>
          <span className={'flex flex-col'}>
            <span className={styles.title}>Teens</span>
            <span className={styles.desc}>Ages 13-18</span>
          </span>
          <span className={'flex items-center'}>
            <Button
              size={'small'}
              icon={<MinusOutlined />}
              shape={'circle'}
              onClick={() => setTeensCount(teensCount - 1)}
              disabled={teensCount <= 0}
            />
            <span className={styles.count}>{teensCount}</span>
            <Button size={'small'} icon={<PlusOutlined />} shape={'circle'} onClick={() => setTeensCount(teensCount + 1)} />
          </span>
        </div>
      )}
      {['Family', 'Friends'].includes(type) && (
        <div className={'flex items-center justify-between w-full px-3 mt-5'}>
          <span className={'flex flex-col'}>
            <span className={styles.title}>Children</span>
            <span className={styles.desc}>Ages 2-12</span>
          </span>
          <span className={'flex items-center'}>
            <Button
              size={'small'}
              icon={<MinusOutlined />}
              shape={'circle'}
              onClick={() => setChildrenCount(childrenCount - 1)}
              disabled={childrenCount <= 0}
            />
            <span className={styles.count}>{childrenCount}</span>
            <Button size={'small'} icon={<PlusOutlined />} shape={'circle'} onClick={() => setChildrenCount(childrenCount + 1)} />
          </span>
        </div>
      )}
      <Button type="primary" className={'!rounded-[22px] !px-10 mt-8 mb-4'} onClick={onDone}>
        Done
      </Button>
    </div>
  );
}

export default WhoPanel;

import { notification } from 'antd';
import { ClockCircleFilled } from '@ant-design/icons';
import { ZZThemeColor } from '@/utils/config.js';

notification.config({
  top: 55
});

export default {
  info(config) {
    notification.info({
      duration: 4,
      placement: 'topRight',
      closeIcon: null,
      className: 'my-notification',
      ...config
    });
  },
  success(config) {
    notification.success({
      duration: 4,
      placement: 'topRight',
      closeIcon: null,
      className: 'my-notification',
      ...config
    });
  },
  error(config) {
    notification.error({
      duration: 4,
      placement: 'topRight',
      closeIcon: null,
      className: 'my-notification',
      ...config
    });
  },

  processing(config) {
    notification.info({
      duration: 4,
      placement: 'topRight',
      closeIcon: null,
      className: 'my-notification',
      icon: (
        <ClockCircleFilled
          style={{
            color: ZZThemeColor,
            fontSize: 20
          }}
        />
      ),
      ...config
    });
  }
};

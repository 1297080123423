import { Select } from 'antd';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTripStoreSelectorsV4 } from '@/stores/useTripStore_v4.js';

let service = null;
let token = null;

function GoogleMapSearchBox({ types = ['locality', 'country', 'administrative_area_level_1'], ...props }, ref) {
  const [results, setResults] = useState([]);
  const selectRef = useRef(null);
  const pickedIntent = useTripStoreSelectorsV4.use.pickedIntent();

  useEffect(() => {
    init();
  }, []);

  useImperativeHandle(ref, () => ({
    triggerSearch(target) {
      onSearch(target);
    },
    focus() {
      selectRef?.current?.focus();
    }
  }));

  const init = async () => {
    await window.google.maps.importLibrary('places');
    service = new window.google.maps.places.AutocompleteService();
    token = new window.google.maps.places.AutocompleteSessionToken();
  };

  const onSearch = async value => {
    props.onSearch && props.onSearch(value);
    if (!service) {
      await init();
    }
    const destination = pickedIntent.destination_places[0];
    const center = { lat: Number(destination?.latitude), lng: Number(destination?.longitude) };
    const distanceInDegrees = 500 / 111;
    service.getPlacePredictions(
      {
        input: value,
        types: types,
        language: 'en',
        sessionToken: token,
        // locationBias: {
        //   radius: 500000, //500km
        //   center
        // }
        locationRestriction: {
          north: center.lat + distanceInDegrees, // 北边界
          south: center.lat - distanceInDegrees, // 南边界
          east: center.lng + distanceInDegrees, // 东边界
          west: center.lng - distanceInDegrees // 西边界
        }
      },
      (predictions, status) => {
        console.log('predictions', predictions);
        if (status === 'OK') {
          setResults(
            predictions.map(item => {
              return {
                value: item.description,
                label: item.description,
                extra: item
              };
            })
          );
        }
      }
    );
  };

  const onChange = (v, option) => {
    if (props.onChange) {
      props.onChange(v, option);
    }
  };

  return (
    <Select
      {...props}
      ref={selectRef}
      showSearch
      defaultActiveFirstOption={false}
      suffixIcon={props.suffixIcon}
      filterOption={false}
      onSearch={onSearch}
      onChange={onChange}
      notFoundContent={null}
      options={results || []}
    />
  );
}

const Index = forwardRef(GoogleMapSearchBox);

export default Index;

import { ZZEmittedEvent } from '@/utils/const.js';
import { useEffect, useRef, useState } from 'react';
import useTripStoreV4, { useTripStoreSelectorsV4 } from '@/stores/useTripStore_v4.js';
import { cloneDeep, isEmpty } from 'lodash-es';
import PlaceMarker from '@/components/home-v4/map-elements/place-marker.jsx';
import ReactDOM from 'react-dom/client';
import emitter from '@/utils/emitter.js';
import { useGlobalStoreSelectors } from '@/stores/useGlobalStore.js';
import { CheckOutlined } from '@ant-design/icons';
import styles from './map-panel.module.scss';
import { logEvent } from '@/utils/ga.js';

let map = null;
let markers = [];
let markerClusterer = null;
let directionsRenderer = null;
let directionsService = null;

const default_center = {
  lat: 48.8566,
  lng: 2.3522
};

function MapPanel({ initialTriggerItinerary, className = '' }) {
  const toggleIsShowPlaceInfo = useGlobalStoreSelectors.use.toggleIsShowPlaceInfo();
  const itinerary = useTripStoreSelectorsV4.use.itinerary();
  const pickedIntent = useTripStoreSelectorsV4.use.pickedIntent();
  const [itineraryDays, setItineraryDays] = useState([]);
  const [itineraryDay, setItineraryDay] = useState(undefined);
  const state = useRef({
    mapLoaded: false
  });

  useEffect(() => {
    initEvent();
    initMap();
    return () => {
      emitter.off(ZZEmittedEvent.focusAttraction);
    };
  }, []);

  useEffect(() => {
    const unsub1 = useTripStoreV4.subscribe(
      state => state.mapPlaces,
      () => {
        onPlacesChange();
      }
    );
    return () => unsub1();
  }, []);

  useEffect(() => {
    onItineraryChange();
  }, [itinerary]);

  useEffect(() => {
    onPantoDestination();
  }, [pickedIntent]);

  useEffect(() => {
    if (itineraryDay == null) {
      onPlacesChange();
    } else {
      generateRoute();
    }
  }, [itineraryDay]);

  const initEvent = () => {
    emitter.on(ZZEmittedEvent.focusAttraction, item => {
      map.panTo({
        lat: Number(item?.latitude),
        lng: Number(item?.longitude)
      });
    });
    emitter.on(ZZEmittedEvent.focusTargetDay, target => {
      onDayChange(target);
      map?.setZoom(13);
    });
  };

  const initMap = async () => {
    const { Map } = await window.google.maps.importLibrary('maps');
    await window.google.maps.importLibrary('marker');
    const { pickedIntent } = useTripStoreV4.getState();
    const destination = pickedIntent?.destination_places?.[0];
    map = new Map(document.getElementById('map'), {
      zoom: 11,
      center: destination ? { lat: Number(destination?.latitude), lng: Number(destination?.longitude) } : default_center,
      mapId: 'bfe364ad9b05ea00',
      fullscreenControl: false,
      mapTypeControl: false,
      zoomControlOptions: {
        position: window.google.maps.ControlPosition.LEFT_BOTTOM
      },
      streetViewControlOptions: {
        position: window.google.maps.ControlPosition.LEFT_BOTTOM
      }
    });
    // 关联样式和地图
    // const styledMapType = new window.google.maps.StyledMapType(ZZMapStyle, { name: 'Styled Map' });
    // map.mapTypes.set('styled_map', styledMapType);
    // map.setMapTypeId('styled_map');
    directionsService = new window.google.maps.DirectionsService();
    directionsRenderer = new window.google.maps.DirectionsRenderer({
      map,
      suppressMarkers: true
    });
    map.addListener('tilesloaded', () => {
      if (state.current.mapLoaded == false) {
        state.current.mapLoaded = true;
        if (initialTriggerItinerary) {
          map?.setZoom(13);
          setItineraryDay('All');
        } else {
          onPlacesChange();
        }
      }
    });
  };

  const onPantoDestination = () => {
    if (!state.current.mapLoaded) {
      return;
    }
    const { pickedIntent } = useTripStoreV4.getState();
    const destination = pickedIntent?.destination_places[0];
    map?.panTo(
      destination
        ? {
            lat: Number(destination?.latitude),
            lng: Number(destination?.longitude)
          }
        : default_center
    );
    map?.setZoom(11);
  };

  const onPlacesChange = () => {
    setItineraryDay(undefined);
    const { mapPlaces } = useTripStoreV4.getState();
    if (!state.current.mapLoaded) {
      return;
    }
    clearAllItemsOnMap();
    if (isEmpty(mapPlaces)) {
      onPantoDestination();
      return;
    }
    mapPlaces.forEach(item => {
      item.order = '';
      const marker = createAttractionMarker(item);
      marker.addListener('gmp-click', () => {
        toggleIsShowPlaceInfo(true, item.place_id);
      });
      markers.push(marker);
    });
    // fitBoundsByPlaces(mapPlaces);
    markerClusterer = new window.markerClusterer.MarkerClusterer({
      markers,
      map
    });
  };

  const onItineraryChange = () => {
    const days = [];
    let { itinerary } = useTripStoreV4.getState();
    for (let i = 0; i < itinerary?.daily_itineraries.length; i++) {
      days.push({
        value: i,
        label: `Day ${i + 1}`
      });
    }
    days.unshift({
      value: 'All',
      label: `All Days`
    });
    setItineraryDays(days);
    setItineraryDay(undefined);
  };

  const generateRoute = () => {
    try {
      if (!state.current.mapLoaded) {
        return;
      }
      clearAllItemsOnMap();
      if (itineraryDay == null) {
        return;
      }
      const { itinerary } = useTripStoreV4.getState();
      if (!itinerary) {
        return;
      }
      let attractions = [];
      if (itineraryDay === 'All') {
        attractions = itinerary.daily_itineraries
          .flatMap(o => o.places)
          .filter(item => item.place_type === 'attraction' || item.preference_type === 'must_do' || item.preference_type === 'optional');
      } else {
        attractions = itinerary.daily_itineraries[itineraryDay].places.filter(
          item => item.place_type === 'attraction' || item.preference_type === 'must_do' || item.preference_type === 'optional'
        );
      }
      attractions = cloneDeep(attractions);
      attractions.forEach((item, index) => {
        item.order = '';
        item.name = `${index + 1}. ${item.name}`;
        const marker = createAttractionMarker(item, item.color);
        marker.addListener('gmp-click', () => {
          toggleIsShowPlaceInfo(true, item.place_id);
        });
        markers.push(marker);
      });
      if (itineraryDay !== 'All') {
        if (attractions.length < 2) {
          panTo(attractions[0]);
          return;
        }
        const [origin, ...others] = attractions;
        const destination = others.pop();
        console.log('others', others);
        const options = {
          travelMode: 'WALKING',
          origin: {
            lat: Number(origin.latitude),
            lng: Number(origin.longitude)
          },
          destination: {
            lat: Number(destination.latitude),
            lng: Number(destination.longitude)
          },
          waypoints: others.map(item => {
            return {
              location: {
                lat: Number(item.latitude),
                lng: Number(item.longitude)
              },
              stopover: true
            };
          })
        };
        directionsRenderer.setOptions({
          polylineOptions: {
            strokeColor: '#4096ff'
          }
        });
        directionsService.route(options, function(result, status) {
          console.log('result--->', result);
          if (status == 'OK') {
            directionsRenderer.setDirections(result);
          } else {
            if (!isEmpty(attractions)) {
              panTo(attractions[0]);
            }
          }
        });
      }
    } catch (e) {
      console.log('e', e);
    }
  };

  const panTo = place => {
    if (place?.latitude && place?.longitude) {
      map.panTo({
        lat: Number(place?.latitude),
        lng: Number(place?.longitude)
      });
    }
  };

  const onDayChange = value => {
    logEvent({
      name: 'map_day_change'
    });
    setItineraryDay(prev => {
      if (prev == value) {
        return null;
      } else {
        return value;
      }
    });
  };

  const clearAllItemsOnMap = () => {
    console.log('clearAllItemsOnMap');
    try {
      markers.forEach(marker => {
        marker.setMap(null);
      });
      markers = [];
      if (markerClusterer) {
        if (markerClusterer) {
          markerClusterer.clearMarkers();
          markerClusterer = null;
        }
      }
      if (directionsRenderer) {
        directionsRenderer.setDirections({ routes: [] });
      }
    } catch (e) {
      console.log('e-->', e);
    }
  };

  return (
    <div className={`w-full h-full relative ${styles.mapPanel} ${className}`}>
      {/*<span className={'absolute left-3 top-14 z-10'}>*/}
      {/*  <Select*/}
      {/*    allowClear*/}
      {/*    placeholder="Filter daily attractions"*/}
      {/*    className="ant-select-black"*/}
      {/*    onChange={onDayChange}*/}
      {/*    style={{ width: 160 }}*/}
      {/*    options={itineraryDays}*/}
      {/*    value={itineraryDay}*/}
      {/*  />*/}
      {/*</span>*/}
      <span
        className={'absolute left-4 top-14 md:top-4 bg-white rounded-md flex flex-col overflow-x-hidden overflow-y-auto max-h-[175px]'}
        id="day-select"
        style={{ zIndex: 1000 }}
      >
        {itineraryDays.map(item => (
          <span
            className={`flex items-center justify-between p-2 py-1.5 text-[13px] cursor-pointer hover:bg-[#f2f3f5] ${
              item.value == itineraryDay ? '!bg-[#0587f0] text-[white] font-medium' : ''
            }`}
            key={item.value}
            onClick={() => onDayChange(item.value)}
          >
            <span className={'mr-3 flex-shrink-0'}>{item.label}</span>
            <CheckOutlined className={`text-[#0587f0] ${item.value == itineraryDay ? 'visible' : 'invisible'}`} />
          </span>
        ))}
      </span>
      <div
        id="map"
        style={{
          width: '100%',
          height: '100%'
        }}
      />
    </div>
  );
}

function createAttractionMarker(place, color) {
  const container = document.createElement('div');
  container.id = `marker-${place.place_id}`;
  const root = ReactDOM.createRoot(container);
  root.render(<PlaceMarker place={place} color={color} />);

  let marker = new window.google.maps.marker.AdvancedMarkerView({
    position: {
      lat: Number(place?.latitude),
      lng: Number(place?.longitude)
    },
    map: map,
    content: container
  });
  marker.markerId = place.place_id;
  return marker;
}

export default MapPanel;

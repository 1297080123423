import { useEffect, useState, useCallback, memo } from 'react';
import styles from './direction-box.module.scss';
import useTripStoreV4, { useTripStoreSelectorsV4 } from '@/stores/useTripStore_v4.js';
import IconCar from '../../../assets/svgs/icon_car.svg?react';
import IconWalk from '../../../assets/svgs/icon_walk.svg?react';
import IconBike from '../../../assets/svgs/icon_bike.svg?react';
import IconTransit from '../../../assets/svgs/icon_transit.svg?react';
import { getDirections } from '@/apis/trips.js';
import { LoadingOutlined } from '@ant-design/icons';
import { get } from 'lodash-es';

function Methods({ isShowContent, from, end = false, isShowFromText, isShowEndText = false }) {
  const [method, setMethod] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const addRoute = useTripStoreSelectorsV4.use.addRoute();

  useEffect(() => {
    getDistance();
  }, [from, end]);

  const onDirection = useCallback(() => {
    let f = null;
    let t = null;
    if (from) {
      f = `${from.name},${from.city}`;
    }
    if (end) {
      t = `${end.name},${end.city}`;
    }
    const url = `https://www.google.com/maps/dir/${f}/${t}`;
    console.log('url--->', url);
    window.open(url, '_blank');
  }, [from, end]);

  const getDistance = async () => {
    try {
      if (from && end && from.name && end.name) {
        const { routes } = useTripStoreV4.getState();
        const found = routes.find(item => item.from === from.place_id && item.to === end.place_id);
        if (found && found?.route?.distance_in_km != null) {
          console.log('found--->', found);
          setMethod(found.route);
        } else {
          setIsLoading(true);
          const { sessionId } = useTripStoreV4.getState();
          const data = await getDirections({
            session_id: sessionId,
            requests: [
              {
                from_place: {
                  name: from.name,
                  latitude: from.latitude,
                  longitude: from.longitude
                },
                to_place: {
                  name: end.name,
                  latitude: end.latitude,
                  longitude: end.longitude
                },
                traffic_mode: 'driving'
              }
            ]
          });
          if (get(data, '0.duration_minutes') != null) {
            setMethod(data[0]);
            addRoute({
              from: from.place_id,
              to: end.place_id,
              route: data[0]
            });
          }
        }
      }
    } catch (e) {
      console.log('e-->', e);
    } finally {
      setIsLoading(false);
    }
  };

  // const getLevelColor = distance => {
  //   if (distance > 50 && distance <= 200) {
  //     return 'text-[#6B7280]';
  //   } else if (distance > 200) {
  //     return 'text-[#FF8C00]';
  //   }
  //   return 'text-[#999]';
  // };

  const getIcon = method => {
    return {
      DRIVING: <IconCar />,
      WALKING: <IconWalk />,
      BICYCLING: <IconBike />,
      TRANSIT: <IconTransit />
    }[method];
  };

  return isShowContent ? (
    <div className={`relative flex items-center py-3 ${styles.box}`}>
      {isLoading && <LoadingOutlined className={'mr-2'} />}
      {isShowFromText && <span className={'mr-2'}>&nbsp;from {from.name}</span>}
      {isShowEndText && <span className={'mr-2'}>&nbsp;to {end.name}</span>}
      {!!method?.distance_in_km && (
        <>
          <span>{method?.distance_in_km} km</span>
          <span className={'mx-2'}>•</span>
        </>
      )}
      {!!method?.duration_minutes && (
        <>
          <span className={'f-center'}>
            {getIcon(method?.traffic_mode)}
            <span className={'ml-1'}>{method?.duration_minutes} mins</span>
          </span>
          <span className={'mx-2'}>•</span>
        </>
      )}
      <span onClick={onDirection} className={'text-[#313131] cursor-pointer underline'}>
        Directions
      </span>
    </div>
  ) : null;
}

const MethodsMemo = memo(Methods, (prevProps, nextProps) => {
  return (
    prevProps.isShowContent === nextProps.isShowContent &&
    prevProps.from?.name === nextProps.from?.name &&
    prevProps.end?.name === nextProps.end?.name
  );
});

export default MethodsMemo;

import { Modal, Spin } from 'antd';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import styles from './arrange-days-modal.module.scss';
import { useEffect, useState } from 'react';
import useTripStore_v4, { useTripStoreSelectorsV4 } from '@/stores/useTripStore_v4.js';
import { formatItineraryDate } from '@/utils/index.js';
import { swap_day } from '@/apis/trips_v4.js';
import { LoadingOutlined } from '@ant-design/icons';

function ArrangeDaysModal({ onCancel, ...props }) {
  const [isLoading, setIsLoading] = useState(false);
  const itinerary = useTripStoreSelectorsV4.use.itinerary();
  const updateItinerary = useTripStoreSelectorsV4.use.updateItinerary();
  const [itineraryCloned, setItineraryCloned] = useState();

  useEffect(() => {
    setItineraryCloned(itinerary);
  }, [itinerary]);

  const onClose = () => {
    onCancel();
  };

  const onDragEnd = async result => {
    try {
      setIsLoading(true);
      const { source, destination } = result;
      const from_day_index = source.index + 1;
      const to_day_index = destination.index + 1;
      if (!destination || from_day_index === to_day_index) {
        return;
      }
      let arr = Array.from(itineraryCloned.daily_itineraries);
      const [remove] = arr.splice(source.index, 1);
      arr.splice(destination.index, 0, remove);
      const { sessionId, itineraryId } = useTripStore_v4.getState();
      const data = await swap_day({
        session_id: sessionId,
        itinerary_id: itineraryId,
        from_day_index,
        to_day_index
      });
      updateItinerary(data, false);
    } catch (e) {
      console.log('e', e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      {...props}
      className={styles.arrangeModal}
      centered
      width={600}
      onOk={onClose}
      onCancel={onCancel}
      cancelButtonProps={{
        style: {
          display: 'none'
        }
      }}
      okText="Close"
      okButtonProps={{
        style: {
          width: 120,
          height: 40,
          lineHeight: 1,
          borderRadius: 20
        }
      }}
      title={
        <div className={'f-center'}>
          <Spin spinning={isLoading} size="small" indicator={<LoadingOutlined style={{ fontSize: 20, color: 'black', marginRight: 12 }} />} />
          <span>Arrangement</span>
        </div>
      }
    >
      <div className="my-6">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="my-droppable">
            {provided => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {itineraryCloned.daily_itineraries.map((item, index) => (
                  <Draggable draggableId={item.id} index={index} key={item.id}>
                    {provided => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="flex items-start mb-4 font-medium"
                      >
                        <i className="iconfont icon-rcd-drag mr-1 text-[20px]" />
                        <div className={'flex flex-col ml-2 pt-0.5 text-[14px]'}>
                          {!!item.date && formatItineraryDate(item.date)}
                          <div className="flex items-center">
                            <span className={'mr-3 flex items-center'}>Day {index + 1}</span>
                            {!!item.introduction && <span className={'text-[#666]'}>{item.introduction}</span>}
                          </div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {/* TODO: 在这里渲染 Draggable 组件 */}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </Modal>
  );
}

export default ArrangeDaysModal;

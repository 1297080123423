import styles from './day-hotels.module.scss';
import emitter from '@/utils/emitter.js';
import { ZZEmittedEvent } from '@/utils/const.js';
import { EditOutlined } from '@ant-design/icons';
import { useTripStoreSelectorsV4 } from '@/stores/useTripStore_v4.js';
import { useGenerateStoreSelectors } from '@/stores/useGenerateStore.js';
import toast from '@/utils/toast.js';

function DayHotels({ items, day, dayIndex, className, ...props }) {
  const isReadOnly = useTripStoreSelectorsV4.use.isReadOnly();
  const isGenerating = useGenerateStoreSelectors.use.isGenerating();

  const onClickHotel = hotel => {
    if (isReadOnly) {
      return;
    }
    if (isGenerating) {
      toast.info('The itinerary is generating...');
      return;
    }
    if (hotel.place_type === 'place_to_stay') {
      emitter.emit(ZZEmittedEvent.editStayCity, {
        hotel,
        day,
        dayIndex
      });
    } else if (hotel.place_type === 'hotel') {
      emitter.emit(ZZEmittedEvent.changeTripTab, 'Hotels');
    }
  };

  return (
    <div className={`${className}`} {...props}>
      {items.map(item => {
        return (
          <div key={item.id} className={`flex items-center justify-between cursor-pointer ${styles.item}`}>
            <span className={'f-center'}>
              <svg className="icon w-7 h-7 mr-1.5" aria-hidden="true">
                <use xlinkHref="#icon-a-chuangxiuxishuijue" />
              </svg>
              <span>{item.text}</span>
              {item.operation != 'Check out' && (
                <EditOutlined className={`text-[18px] ml-2 text-[#666] ${isReadOnly ? 'hidden' : ''}`} onClick={() => onClickHotel(item)} />
              )}
              {/*<EditOutlined className="text-[18px] ml-3 text-[#666]" onClick={() => onClickHotel(item)} />*/}
            </span>
            {item.operation != 'Stay' && <span className={'text-[#777] shrink-0 ml-3'}>{item.operation}</span>}
          </div>
        );
      })}
    </div>
  );
}

export default DayHotels;

import axios from 'axios';
import { ZZBaseUrl } from '@/utils/config';
import cache from '@/utils/cache';
import toast from '@/utils/toast.js';
import { tagDeepInteraction } from '@/utils/ga.js';
// import { logOnCall } from '@/utils/log.js';
// import axiosRetry from 'axios-retry';

// axios.defaults.timeout = 30 * 1000; // 120s
const config = {
  baseURL: ZZBaseUrl,
  timeout: 30 * 10000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
};

const service = axios.create(config);
// axiosRetry(service, {
//   retries: 1,
//   onRetry(retryCount, _, requestConfig) {
//     logOnCall({
//       action: 'retry',
//       retryCount,
//       requestConfig
//     });
//   }
// });

// 添加请求拦截器
service.interceptors.request.use(
  config => {
    if (cache.getToken()) {
      config.headers.set('Authorization', `Bearer ${cache.getToken()}`);
    }
    const query = new URLSearchParams(window.location.search);
    query.forEach((val, key) => {
      if (key.toLowerCase().startsWith('exp-')) {
        config.headers.set(key, val);
      }
    });
    config.params = {
      deviceId: cache.getDeviceId(),
      ...config.params
    };
    config.data = {
      deviceId: cache.getDeviceId(),
      ...(config.data || {})
    };
    return config;
  },
  error => Promise.reject(error)
);

// // 返回状态判断(添加响应拦截器)
// service.interceptors.response.use(
//   res => res,
//   error => {
//     if (axios.isCancel(error)) {
//       console.log('cancel request');
//     } else {
//       Promise.reject(error);
//     }
//   }
// );

/**
 * 封装了几个通用的请求函数
 */
export default async function ajax({ url, params = null, data = null, hideLoading = false, method = 'POST' }) {
  if (!url.includes('get_directions')) {
    tagDeepInteraction();
  }
  if (hideLoading === false) {
    // ZZToast.loading();
  }
  try {
    const result = await service({
      url,
      method,
      params,
      data
    });
    const res = result.data;
    // ZZToast.hideLoading();
    if (res && res.code === 200) {
      return res.data;
    }
    if (res && res.code === 401) {
      // cache.clearToken();
      // window.location.reload();
    } else {
      const msg = res.msg || res.message || 'network failed';
      toast.error(msg);
      throw new Error(msg);
    }
  } catch (error) {
    console.log('error--->', error);
    // if (!url.includes('/oncall')) {
    //   logOnCall({
    //     url,
    //     params,
    //     data,
    //     error
    //   });
    throw new Error(error);
    // }
  }
}

import { EditOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { useEffect, useState } from 'react';
import styles from './day-item.module.scss';
import { useTripStoreSelectorsV4 } from '@/stores/useTripStore_v4.js';
import MustDosPanel from '@/components/home-v4/itinerary-elements/must-dos-panel.jsx';
import { convertMinutesToHours, formatItineraryDate } from '@/utils/index.js';
import { isEmpty } from 'lodash-es';
import { nanoid } from 'nanoid';
import DayFlights from '@/components/home-v4/itinerary-elements/day-flights.jsx';
import DayHotels from '@/components/home-v4/itinerary-elements/day-hotels.jsx';
import IconClear from '../../../assets/svgs/icon_clear.svg?react';
import IconCamera from '../../../assets/svgs/icon_camera_black.svg?react';
import Methods from '@/components/home-v4/itinerary-elements/direction-box.jsx';
import toast from '@/utils/toast.js';
import { useGenerateStoreSelectors } from '@/stores/useGenerateStore.js';
import { useResponsive } from 'ahooks';

function DayItem({ index, day, lastDay, onAddPlace, onEditDuration, onEditDayTitle }) {
  const { md } = useResponsive();
  const isReadOnly = useTripStoreSelectorsV4.use.isReadOnly();
  const [modal, contextHolder] = Modal.useModal();
  // const deleteDay = useTripStoreSelectorsV4.use.deleteDay();
  const clearDay = useTripStoreSelectorsV4.use.clearDay();
  const [mustDos, setMustDos] = useState([]);
  const [lastDayHotel, setLastDayHotel] = useState(null);
  const [currentDayHotel, setCurrentDayHotel] = useState(null);
  const [flights, setFlights] = useState([]);
  const [hotels, setHotels] = useState([]);
  const isGenerating = useGenerateStoreSelectors.use.isGenerating();
  // const insertDay = useTripStoreSelectorsV4.use.insertDay();

  useEffect(() => {
    initData();
  }, [day]);
  //
  const initData = () => {
    if (isEmpty(day.places)) {
      setMustDos([]);
    } else {
      const mustDos = day.places.filter(
        item => item.place_type === 'attraction' || item.preference_type === 'must_do' || item.preference_type === 'optional'
      );
      setMustDos(mustDos);
    }
    let flights = [];
    if (day.flight) {
      flights.push({
        id: nanoid(4),
        place_type: 'airport',
        text: `${day.flight?.origin?.name}   ➡️ ︎ ${day.flight?.destination?.name}`
      });
    }
    let hotels = [];
    const currentDayHotel = getDayHotel(day);
    const lastDayHotel = getDayHotel(lastDay);
    if (currentDayHotel) {
      if (lastDayHotel?.text == currentDayHotel?.text) {
        currentDayHotel.operation = 'Stay';
        hotels = [currentDayHotel];
      } else {
        if (lastDayHotel) {
          lastDayHotel.operation = 'Check out';
          hotels.push(lastDayHotel);
        }
        currentDayHotel.operation = 'Check in';
        hotels.push(currentDayHotel);
      }
    } else if (lastDayHotel) {
      lastDayHotel.operation = 'Check out';
      hotels.push(lastDayHotel);
    }
    setCurrentDayHotel(currentDayHotel);
    setLastDayHotel(lastDayHotel);
    setFlights(flights);
    setHotels(hotels);
  };

  const getDayHotel = day => {
    if (day?.hotel) {
      return {
        id: nanoid(4),
        place_type: 'hotel',
        text: day.hotel.address,
        name: day.hotel.address,
        place_id: day?.hotel?.place_id
      };
    } else if (day?.place_to_stay) {
      return {
        id: nanoid(4),
        place_type: 'place_to_stay',
        text: day.place_to_stay?.name,
        name: day.place_to_stay?.name,
        latitude: day?.place_to_stay?.latitude,
        longitude: day?.place_to_stay?.longitude,
        place_id: day?.place_to_stay?.place_id
      };
    }
    return null;
  };

  // const onAddDayBefore = () => {
  //   modal.confirm({
  //     centered: true,
  //     title: 'Add Confirm',
  //     content: 'Are you sure to add it before this day?',
  //     onOk: async close => {
  //       // await insertDay(index + 1);
  //       await insertDay(index + 1, 'before');
  //       close();
  //     },
  //     onCancel: () => {
  //       console.log('cancel');
  //     }
  //   });
  // };

  // const onAddDayAfter = () => {
  //   modal.confirm({
  //     centered: true,
  //     title: 'Add Confirm',
  //     content: 'Are you sure to add it after this day?',
  //     onOk: async close => {
  //       // await insertDay(index + 2); //后台第一天是1
  //       await insertDay(index + 1, 'after');
  //       close();
  //     },
  //     onCancel: () => {
  //       console.log('cancel');
  //     }
  //   });
  // };

  // const menus = {
  //   items: [
  //     {
  //       key: '2',
  //       label: (
  //         <div className="py-1" onClick={onAddDayBefore}>
  //           Add day before
  //         </div>
  //       )
  //     },
  //     {
  //       key: '3',
  //       label: (
  //         <div className="py-1" onClick={onAddDayAfter}>
  //           Add day after
  //         </div>
  //       )
  //     },
  //     {
  //       key: '5',
  //       label: (
  //         <div className="py-1" onClick={onDeleteDay}>
  //           Delete day
  //         </div>
  //       )
  //     }
  //   ]
  // };

  const onClearDay = async () => {
    if (isReadOnly) {
      toast.info('Please save as your own itinerary first.');
      return;
    }
    if (isGenerating) {
      toast.info('The itinerary is generating...');
      return;
    }
    modal.confirm({
      centered: true,
      width: md ? 400 : '90vw',
      className: 'confirmModal',
      icon: null,
      closable: true,
      title: 'Clear Confirm',
      content: 'Are you sure to clear this day?',
      okText: 'Confirm',
      onOk: async close => {
        await clearDay(day.dayIndex + 1);
        close();
      },
      onCancel: () => {
        console.log('cancel');
      }
    });
  };

  // const onDeleteDay = useCallback(() => {
  //   modal.confirm({
  //     centered: true,
  //     title: 'Delete Confirm',
  //     content: 'Are you sure to delete this day?',
  //     onOk: async close => {
  //       await deleteDay(index + 1);
  //       close();
  //     },
  //     onCancel: () => {
  //       console.log('cancel');
  //     }
  //   });
  // }, []);

  const handleUpdateTitle = e => {
    e.stopPropagation();
    if (isReadOnly) {
      toast.info('Please save as your own itinerary first.');
      return;
    }
    if (isGenerating) {
      toast.info('The itinerary is generating...');
      return;
    }
    onEditDayTitle(day);
  };

  const getTotalHours = () => {
    const totalMinutes = mustDos?.reduce((acc, cur) => {
      return acc + (cur.duration_minutes || 120);
    }, 0);
    return convertMinutesToHours(totalMinutes);
  };

  return (
    <div className={`flex flex-col ${styles.dayItem} p-0 px-2 pb-2 md:px-4 md:pb-4 mb-3`} id={`itinerary-day-${index}`}>
      <div className="header flex items-center justify-between mb-3">
        <div className={`f-center flex-shrink flex flex-col w-0 flex-grow ${styles.header}`}>
          <div className={'mb-1 self-stretch flex items-center justify-between font-medium text-[16px] md:text-[24px]'}>
            <div className={'flex items-center mr-3 w-0 flex-grow'}>
              <span className={'shrink-0'}>Day {day.dayIndex + 1}</span>
              <span className={styles.dayTitle}>
                {day.introduction ? (
                  <span className={`ml-2 flex-shrink ellipsis-1 ${!day.introduction ? 'hidden' : ''}`}>{day.introduction}</span>
                ) : (
                  <span className={`ml-2 ${styles.addTitle} ${isReadOnly ? 'hidden' : 'shrink-0'}`} onClick={handleUpdateTitle}>
                    Add Title
                  </span>
                )}
                <EditOutlined className={`text-[16px] md:text-[17px] ml-2 md:hidden ${styles.titleEdit}`} onClick={handleUpdateTitle} />
              </span>
              {/*<span className={`ml-2 flex-shrink ellipsis-1 ${!day.introduction ? 'hidden' : ''}`}>{day.introduction}</span>*/}
              {/*{!day.introduction && (*/}
              {/*  <span className={`ml-2 ${styles.addTitle} ${isReadOnly ? 'hidden' : 'shrink-0'}`} onClick={handleUpdateTitle}>*/}
              {/*    Add Title*/}
              {/*  </span>*/}
              {/*)}*/}
            </div>
            <span className={`${styles.btns} md:hidden`}>
              <Button size={'small'} icon={<IconClear />} onClick={onClearDay}>
                Clear
              </Button>
            </span>
          </div>
          <div className={'self-stretch flex items-center text-[14px]'}>
            {day.date && (
              <template>
                <span>{formatItineraryDate(day.date)}</span>
                <span className={'text-[18px] mx-2 text-[#6B7280]'}>·</span>
              </template>
            )}
            <span>{mustDos?.length || 0} attractions</span>
            <span className={'text-[18px] mx-2 text-[#6B7280]'}>·</span>
            <span>{getTotalHours()}</span>
          </div>
        </div>
      </div>
      <div>
        {!isEmpty(flights) && index == 0 && <DayFlights items={flights} className={'mb-3'} />}
        {!isEmpty(hotels) && <DayHotels dayIndex={day.dayIndex} items={hotels} className={'mb-3'} day={day} />}
        <Methods
          isShowContent={!isEmpty(mustDos) && (lastDayHotel?.operation == 'Check out' || currentDayHotel?.operation == 'Stay')}
          from={lastDayHotel || currentDayHotel}
          end={mustDos[0]}
          isShowFromText={lastDayHotel?.operation == 'Check out'}
        />
        <MustDosPanel
          isShowFrom={lastDayHotel?.operation == 'Check out' || currentDayHotel?.operation == 'Stay'}
          dayIndex={day.dayIndex}
          day={day}
          mustDos={mustDos}
          onEditDuration={onEditDuration}
        />
        <Methods isShowContent={!isEmpty(mustDos) && currentDayHotel} from={mustDos.at(-1)} end={currentDayHotel} isShowEndText />
        {!isEmpty(flights) && index != 0 && <DayFlights items={flights} className={'mt-3'} />}
      </div>
      <div className={'w-full mt-6 pt-3 pb-2 f-center'} style={{ borderTop: '1px solid #E5E7EB' }}>
        <Button icon={<IconCamera />} className={`${isReadOnly ? '!hidden' : ''} self-center mt-2`} onClick={() => onAddPlace(day)}>
          Add Attractions
        </Button>
      </div>
      {contextHolder}
    </div>
  );
}

export default DayItem;

import { useState } from 'react';

export default function YoutubeCover({ item, className, mask }) {
  const [url, setUrl] = useState(item.thumbnail?.static || '');
  const [error, setError] = useState(false);

  const onError = () => {
    setError(true);
  };

  return (
    <div className={`relative ${className} overflow-hidden`}>
      <img
        src={error ? item.thumbnail?.static : url}
        alt=""
        onMouseEnter={() => (item.thumbnail?.rich ? setUrl(item.thumbnail?.rich) : null)}
        onMouseOut={() => setUrl(item.thumbnail?.static)}
        onError={onError}
        className={'w-full h-full rounded-md object-cover'}
      />
      {mask ? <div className={'absolute top-0 left-0 w-full h-full bg-black opacity-20 rounded-md'} /> : null}
    </div>
  );
}

import styles from './day-flights.module.scss';

function DayFlights({ items, className, ...props }) {
  return (
    <div className={`${className}`} {...props}>
      {items.map(item => {
        return (
          <div key={item.id} className={`flex items-center ${styles.item}`}>
            <svg className="icon w-7 h-7 mr-1.5" aria-hidden="true">
              <use xlinkHref="#icon-jichang" />
            </svg>
            <span>{item.text}</span>
          </div>
        );
      })}
    </div>
  );
}

export default DayFlights;

import AttractionItem from '@/components/home-v4/itinerary-elements/attraction-item.jsx';
import { get, isEmpty } from 'lodash-es';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import Methods from '@/components/home-v4/itinerary-elements/direction-box.jsx';
import { useTripStoreSelectorsV4 } from '@/stores/useTripStore_v4.js';
import { useGenerateStoreSelectors } from '@/stores/useGenerateStore.js';

function MustDosPanel({ day, dayIndex, mustDos, onEditDuration, isShowFrom }) {
  const isReadOnly = useTripStoreSelectorsV4.use.isReadOnly();
  const isGenerating = useGenerateStoreSelectors.use.isGenerating();

  return (
    <Droppable droppableId={day.id}>
      {provided => (
        <div ref={provided.innerRef} {...provided.droppableProps}>
          {mustDos?.map((attraction, index) => {
            return (
              <Draggable index={index} draggableId={attraction.key} key={attraction.key} isDragDisabled={isReadOnly || isGenerating}>
                {(provided, snapshot) => (
                  <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className={``}>
                    <AttractionItem
                      key={get(attraction, 'place_id')}
                      isShowFrom={isShowFrom}
                      place={attraction}
                      dayIndex={dayIndex}
                      attractionIndex={index}
                      isLast={index == mustDos?.length - 1}
                      onEditDuration={() => onEditDuration({ dayIndex: dayIndex, attractionIndex: index, attraction })}
                    />
                    <Methods isShowContent={!snapshot.isDragging && index !== mustDos?.length - 1} from={attraction} end={mustDos[index + 1]} />
                  </div>
                )}
              </Draggable>
            );
          })}
          {isEmpty(mustDos) && <div className={'f-center p-3 text-[#888]'}>No attraction</div>}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
}

export default MustDosPanel;

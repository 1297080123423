import useTripStoreV4, { useTripStoreSelectorsV4 } from '@/stores/useTripStore_v4.js';
import { add_attraction } from '@/apis/trips_v4.js';
import notification from '@/utils/notification.jsx';
import toast from '@/utils/toast.js';
import { nanoid } from 'nanoid';
import { Modal, Select } from 'antd';
import { useRef } from 'react';
import { ZZThemeColor } from '@/utils/config.js';
import cache from '@/utils/cache.js';

export default function usePlaceV4() {
  const updateItinerary = useTripStoreSelectorsV4.use.updateItinerary();
  const isReadOnly = useTripStoreSelectorsV4.use.isReadOnly();
  const state = useRef({
    selectDay: 1
  });

  //添加景点
  const onAddPlace = async (e, place, options = {}) => {
    if (isReadOnly) {
      toast.info('Please save as your own itinerary first.');
      return;
    }
    e?.stopPropagation();
    if (isReadOnly) {
      toast.info('Please save as your own itinerary first.');
      return;
    }
    if (options.dayIndex) {
      await _onAddPlaceToItinerary(place, false, {
        isShowLoading: options.isShowLoading ?? true,
        day_index: options.dayIndex
      });
    } else {
      const { itinerary } = useTripStoreV4.getState();
      const list = itinerary.daily_itineraries.map((_, index) => {
        return {
          label: `Day ${index + 1}`,
          value: index + 1
        };
      });
      list.unshift({
        label: 'Suggest by AI',
        value: -1
      });
      let lastDay = cache.getLastChooseDay();
      const found = list.find(item => item.value == lastDay);
      if (found) {
        state.current.selectDay = found.value;
      } else {
        state.current.selectDay = 1;
      }
      Modal.confirm({
        title: 'Add to Itinerary',
        okButtonProps: {
          type: 'primary',
          style: {
            backgroundColor: ZZThemeColor,
            width: '76px'
          }
        },
        content: (
          <div className={'flex items-center mt-3'}>
            <span className={'mr-3 font-semibold'}>Day:</span>
            <Select className={'!w-40'} options={list} defaultValue={state.current.selectDay} onChange={value => (state.current.selectDay = value)} />
          </div>
        ),
        onOk: async close => {
          cache.setLastChooseDay(state.current.selectDay);
          await _onAddPlaceToItinerary(place, false, {
            isShowLoading: false,
            day_index: state.current.selectDay == -1 ? null : state.current.selectDay
          });
          close();
        }
      });
    }
  };

  const _onAddPlaceToItinerary = async (place, isOptional, { isShowLoading, day_index }) => {
    const key = nanoid(4);
    try {
      const { sessionId, itineraryId } = useTripStoreV4.getState();
      !!isShowLoading &&
        toast.loading({
          key,
          content: `Adding ${place.name} to your trip...`,
          duration: 0
        });
      const data = await add_attraction({
        session_id: sessionId,
        day_index,
        itinerary_id: itineraryId,
        attraction: {
          name: '',
          country: '',
          place_id: place.place_id,
          priority: 'must_do',
          place_type: 'attraction'
        }
      });
      // fetchItineraryAndIntent();
      updateItinerary(data, false);
      notification.success({
        message: `${place.name} added to your trip!`
      });
    } catch (e) {
      console.log('e', e);
    } finally {
      toast.destroy(key);
    }
  };

  return { onAddPlace };
}

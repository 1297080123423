import emitter from '@/utils/emitter.js';
import { ZZEmittedEvent } from '@/utils/const.js';
import { useGlobalStoreSelectors } from '@/stores/useGlobalStore.js';

function UseSource() {
  const toggleIsShowPlaceInfo = useGlobalStoreSelectors.use.toggleIsShowPlaceInfo();

  const onClickSource = ({ source, isCanExtract = true }, e = null) => {
    e?.stopPropagation();
    console.log('source-->', source);
    if (source?.place_id) {
      toggleIsShowPlaceInfo(true, source.place_id);
    } else if (source.type === 'youtube') {
      emitter.emit(ZZEmittedEvent.showPlayer, {
        title: source.source_title || source.title,
        url: source.source_redirect_link || source.url || source.link,
        type: source.type,
        isCanExtract
      });
    } else if (source.type === 'short') {
      console.log('source.url', source.url);
      emitter.emit(ZZEmittedEvent.showPlayer, {
        title: source.source_title || source.title,
        url: source.source_redirect_link || source.url || source.link,
        type: source.type,
        isShorts: true,
        isCanExtract: false
      });
    } else if (source.type === 'reddit' || source.type === 'instagram') {
      emitter.emit(ZZEmittedEvent.showSource, {
        title: source.source_title || source.title,
        url: source.source_redirect_link || source.url || source.link,
        type: source.type,
        isCanExtract
      });
    } else {
      window.open(source.source_redirect_link || source.url || source.link);
    }
  };

  const onExtractPlaces = ({ source }, e) => {
    e.stopPropagation();
    let params = {
      titles: [source.title],
      types: [source.type]
    };
    if (source?.type === 'youtube') {
      params.youtube_urls = [source.url];
    } else {
      params.blog_urls = [source.url];
    }
    emitter.emit(ZZEmittedEvent.extractPlaces, params);
  };

  const getSourceName = ({ source }) => {
    try {
      let name = source?.type;
      if (source?.type === 'webpage') {
        if (source?.url.includes('yelp')) {
          name = 'yelp';
        } else if (source?.url.includes('tripadvisor')) {
          name = 'tripadvisor';
        }
      }
      return name;
    } catch (e) {
      return '';
    }
  };

  return {
    onClickSource,
    getSourceName,
    onExtractPlaces
  };
}

export default UseSource;

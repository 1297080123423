import { Outlet, useLocation } from 'react-router-dom';
import Div100vh from 'react-div-100vh';
import { createContext, useEffect, useRef, useState } from 'react';
import { getTmpToken } from '@/apis/user.js';
import cache from '@/utils/cache.js';
import Header from '@/layouts/header.jsx';
import { LoadingOutlined } from '@ant-design/icons';
import useTripStoreV4 from '@/stores/useTripStore_v4.js';
import { logEvent, tagEvent } from '@/utils/ga.js';

export const GlobalContext = createContext(null);

const ZZNoHeaderPaths = ['/AgodaPartnerVerification'];
function Layout() {
  const [hasAuth, setHasAuth] = useState(false);
  const { pathname } = useLocation();
  const state = useRef({
    interactionCount: 0,
    staySeconds: 0,
    reported: false
  });

  console.log('pathname', pathname);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    if (!cache.getToken()) {
      const token = await getTmpToken({
        session_id: useTripStoreV4.getState().sessionId
      });
      cache.setToken(token);
      setHasAuth(true);
    } else {
      setHasAuth(true);
    }

    const t = setInterval(() => {
      state.current.staySeconds++;
      if (state.current.staySeconds >= 30) {
        clearInterval(t);
        onReport();
      }
    }, 1000);
  };

  useEffect(() => {
    const handleInteraction = () => {
      state.current.interactionCount++;
      // console.log('state.current.interactionCount', state.current.interactionCount);
      onReport();
    };
    document.addEventListener('click', handleInteraction);
    // window.addEventListener('scroll', handleInteraction);
    return () => {
      document.removeEventListener('click', handleInteraction);
      // document.removeEventListener('scroll', handleInteraction);
    };
  }, []);

  const onReport = () => {
    if (state.current.interactionCount >= 5 && state.current.staySeconds >= 30 && !state.current.reported) {
      console.log('Deep Interaction 30S');
      state.current.reported = true;
      logEvent({
        name: 'deep_interaction'
      });
      tagEvent('event', 'conversion', {
        send_to: 'AW-16706051350/MUijCIGWktUZEJa6iJ4-'
      });
    }
  };

  return (
    <Div100vh style={{ backgroundColor: 'white' }} className={`f-center w-full`}>
      <GlobalContext.Provider value={{}}>
        <div className="h-full w-full flex flex-col">
          {ZZNoHeaderPaths.includes(pathname) ? null : <Header />}
          {hasAuth ? (
            <div className="w-full h-0 flex-grow overflow-y-auto">
              <Outlet />
            </div>
          ) : (
            <div className={'h-full f-center'}>
              <LoadingOutlined style={{ fontSize: 36, color: 'black' }} spin />
            </div>
          )}
        </div>
      </GlobalContext.Provider>
    </Div100vh>
  );
}

export default Layout;

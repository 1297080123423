import { create } from 'zustand';
import { createSelectorFunctions } from 'auto-zustand-selectors-hook';
import { subscribeWithSelector } from 'zustand/middleware';
import { ZZUploadType } from '@/utils/const.js';

const useGlobalStore = create(
  subscribeWithSelector((set, get) => ({
    isShowUpload: false,
    operateHotel: null,
    uploadType: ZZUploadType.all,

    isShowPlaceInfo: false,
    placeId: null,

    isShowBooking: false,
    bookingInfo: null,

    toggleIsShowUpload: (flag, uploadType, operateHotel = null) => {
      set(() => ({
        isShowUpload: flag,
        uploadType,
        operateHotel
      }));
    },

    toggleIsShowPlaceInfo: (flag, placeId) => {
      set(() => ({
        isShowPlaceInfo: flag,
        placeId
      }));
    },

    toggleIsShowBooking: (flag, bookingInfo) => {
      set(() => ({
        isShowBooking: flag,
        bookingInfo
      }));
    }
  }))
);

export default useGlobalStore;
export const useGlobalStoreSelectors = createSelectorFunctions(useGlobalStore);

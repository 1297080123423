import { BarsOutlined, CheckCircleFilled, DownOutlined, FileSearchOutlined, LoadingOutlined, SearchOutlined, UpOutlined } from '@ant-design/icons';
import { Divider, Popover, Skeleton } from 'antd';
import { isEmpty, uniqBy } from 'lodash-es';
import styles from './workflow-message.module.scss';
import { useCallback, useEffect, useState } from 'react';
import RichMessage from './rich-message.jsx';
import useSource from '@/hooks/useSource.js';
import SourceOperate from '@/components/home-v4/chat-elements/source-operate.jsx';

function WorkflowMessage({ message }) {
  const { onClickSource } = useSource();

  return (
    <div className={'w-full'}>
      {!isEmpty(message.step) && <StepContent message={message} />}
      {!isEmpty(message.sources) && (
        <div className={'flex flex-col mt-6 first:mt-0'}>
          <div className={'flex items-center mb-2'}>
            <BarsOutlined className={'font-medium text-[18px] mr-2'} />
            <span className={'font-medium text-[17px]'}>Sources</span>
          </div>
          <SourcesRow message={message} onClickSource={source => onClickSource({ source })} />
        </div>
      )}
      {!isEmpty(message.messages) && (
        <div className={'flex flex-col mt-6 first:mt-0'}>
          {/*<div>{parts}</div>*/}
          <RichMessage message={message} />
        </div>
      )}
      {isEmpty(message.messages) && (
        <div className={`mt-5 first:mt-0 ${styles.skeleton} w-full`}>
          <Skeleton active />
        </div>
      )}
    </div>
  );
}

export default WorkflowMessage;

function StepContent({ message }) {
  const { getSourceName } = useSource();
  const [isFold, setIsFold] = useState(false);

  const onFold = useCallback(() => {
    setIsFold(prev => !prev);
  }, []);

  const groupSources = () => {
    if (isEmpty(message.sources)) {
      return [];
    } else {
      return uniqBy(
        message.sources.filter(o => !o.isNotShow),
        'type'
      ).map(o => {
        return (
          <span className={'bg-gray-200 flex items-center px-2 py-0.5 mr-2 rounded-[13px]'} key={o.type}>
            <SourceLogo type={o.type} url={o.url} />
            <span>{getSourceName({ source: o })}</span>
          </span>
        );
      });
    }
  };

  return (
    <div className={`flex flex-col bg-[#f2f3f5] p-3 ${isFold ? 'pb-1' : ''} rounded-[8px]`}>
      <div className={'flex items-center font-medium pb-1.5 border-b text-[17px]'}>
        <i className={'iconfont icon-Star font-bold text-[18px] mr-2'} />
        <span>Search</span>
      </div>
      <div className={'flex mt-2'}>
        <span className={'pt-1.5 flex flex-col items-center mr-1'}>
          {isEmpty(message.sources) && !message.data ? (
            <LoadingOutlined className={'text-[#666]'} />
          ) : (
            <CheckCircleFilled className={'text-[#666]'} />
          )}
          <Divider type="vertical" className={`${isFold ? 'invisible' : 'visible'} h-0 flex-grow`} />
        </span>
        <div className={'w-0 flex-grow px-1 font-medium'}>
          <div className={'flex items-center justify-between cursor-pointer py-1'} onClick={onFold}>
            <span>Researching</span>
            {isFold ? <DownOutlined /> : <UpOutlined />}
          </div>
          {!isFold && !isEmpty(message.sources) ? (
            <>
              <div className={'text-[12px] text-[#777] flex flex-col items-start mt-2'}>
                <div>Searching</div>
                <div className={'bg-gray-200 flex items-center px-2 py-0.5 rounded-[13px] mt-1'}>
                  <SearchOutlined className={'mr-1'} />
                  <span>{message?.step?.action?.query}</span>
                </div>
              </div>
              <div className={'text-[12px] text-[#777] flex flex-col items-start mt-2'}>
                <div>Reading</div>
                <div className={'flex items-center mt-1'}>{groupSources()}</div>
              </div>
              <div className={'text-[12px] text-[#777] flex flex-col items-start mt-2'}>
                <div>Wrapping up</div>
                <div className={'bg-gray-200 flex items-center px-2 py-0.5 rounded mt-1'}>Gathered sufficient information</div>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
}

function SourcesRow({ message, onClickSource }) {
  const [showedSources, setShowedSources] = useState([]);
  const { getSourceName } = useSource();

  useEffect(() => {
    setShowedSources(message.sources.filter(o => !o.isNotShow));
  }, [message]);

  return (
    <div className={'flex overflow-x-auto'}>
      {showedSources.slice(0, 3).map(source => (
        <Popover key={source.source_id} content={<PopoverContent source={source} onClickSource={onClickSource} />}>
          <div
            className={styles.sourceItem}
            onClick={() => {
              onClickSource(source);
            }}
          >
            <div className={`${styles.title} ellipsis-2`}>{source.title}</div>
            <div className={'text-[11px] text-888 flex items-center font-medium text-[#777]'}>
              <SourceLogo type={source.type} url={source?.url} />
              <span>{getSourceName({ source })}</span>
              <span className={'mx-0.5'}>·</span>
              <span>{source.source_id}</span>
            </div>
          </div>
        </Popover>
      ))}
      {showedSources.length > 3 && (
        <Popover content={<AllSourcesPanel message={message} onClickSource={onClickSource} />} placement={'left'} trigger="click">
          <div className={`${styles.sourceItem}`}>
            <span className={'flex items-center mt-2'}>
              {message.sources.slice(3, 7).map(item => (
                <SourceLogo type={item.type} key={item.source_id} url={item.url} />
              ))}
            </span>
            <span className={'text-[12px] font-medium text-[#777]'}>View {showedSources.length - 3} more</span>
          </div>
        </Popover>
      )}
    </div>
  );
}

function SourceLogo({ type, url }) {
  const maps = {
    webpage: (
      <svg className="icon w-4 h-4 mr-1.5" aria-hidden="true">
        <use xlinkHref="#icon-website"></use>
      </svg>
    ),
    youtube: (
      <svg className="icon w-4 h-4 mr-1.5" aria-hidden="true">
        <use xlinkHref="#icon-youtube1"></use>
      </svg>
    ),
    reddit: (
      <svg className="icon w-4 h-4 mr-1.5" aria-hidden="true">
        <use xlinkHref="#icon-reddit"></use>
      </svg>
    )
  };
  let logo = maps[type] || null;
  if (type === 'webpage' && !!url) {
    if (url?.includes('yelp')) {
      logo = (
        <svg className="icon w-4 h-4 mr-1.5 shrink-0" aria-hidden="true">
          <use xlinkHref="#icon-yelp"></use>
        </svg>
      );
    } else if (url?.includes('tripadvisor')) {
      logo = (
        <svg className="icon w-4 h-4 mr-1.5 shrink-0" aria-hidden="true">
          <use xlinkHref="#icon-tripadvisor"></use>
        </svg>
      );
    }
  }
  return logo;
}

function PopoverContent({ source, onClickSource }) {
  const { getSourceName } = useSource();
  const onClick = useCallback(() => {
    onClickSource(source);
  }, []);

  return !source ? null : (
    <div className={'flex flex-col max-w-[400px] cursor-pointer'}>
      <div className={'flex items-center font-medium text-[12px] text-[#777]'}>
        <SourceLogo type={source.type} url={source?.url} />
        <span>{getSourceName({ source })}</span>
        <span className={'mx-0.5'}>·</span>
        <span>{source.source_id}</span>
      </div>
      <div className={'mt-1 text-[13px] font-medium hover:text-blue-500 ellipsis-1'} onClick={onClick}>
        {source.title}
      </div>
      <div className={`mt-1 text-[13px] ellipsis-4`} onClick={onClick}>
        {source.content}
      </div>
      <SourceOperate source={source} />
    </div>
  );
}

function AllSourcesPanel({ message, onClickSource }) {
  const [showedSources, setShowedSources] = useState([]);

  useEffect(() => {
    setShowedSources(message.sources.filter(o => !o.isNotShow));
  }, [message]);

  return (
    <div className={'w-[500px]'}>
      <div className={'text-center font-medium text-[17px]'}>Total {showedSources?.length} sources</div>
      <div className={'flex items-start mt-3 ml-2'}>
        <FileSearchOutlined className={'mt-1 mr-2'} />
        <span className={'text-[#777] text-[13px] font-medium'}>{message.step.action.query}</span>
      </div>
      <div className={'flex flex-col w-full mt-3 max-h-[400px] overflow-y-auto px-2'}>
        {showedSources.map(source => {
          return (
            <div
              key={source.source_id}
              className={'cursor-pointer bg-[#f2f3f5] p-3 rounded-xl mb-3'}
              onClick={() => {
                onClickSource(source);
              }}
            >
              <PopoverContent source={source} onClickSource={() => {}} />
            </div>
          );
        })}
      </div>
    </div>
  );
}

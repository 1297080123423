import { create } from 'zustand';
import { createSelectorFunctions } from 'auto-zustand-selectors-hook';
import { subscribeWithSelector } from 'zustand/middleware';
import emitter from '@/utils/emitter.js';
import { ZZEmittedEvent } from '@/utils/const.js';
import delay from 'delay';

const useGenerateStore = create(
  subscribeWithSelector((set, get) => ({
    isGenerating: false,
    isTyping: false, //是否在打字
    messagesPools: [],

    reset: () => {
      set(() => ({
        isGenerating: false,
        isTyping: false,
        messagesPools: []
      }));
    },

    setIsGenerating: flag => {
      set(() => ({
        isGenerating: flag
      }));
    },

    setIsTyping: flag => {
      set(() => ({
        isTyping: flag
      }));
    },

    addGenerateMessage: async message => {
      await delay(1000);
      const { messagesPools } = get();
      const params = {};
      params.messagesPools = [...messagesPools, message];
      if (message?.data?.state == 'complete itinerary') {
        params.isGenerating = false;
      }
      set(() => params);
      emitter.emit(ZZEmittedEvent.triggerGenerateMessage);
    },

    getOneGenerateMessage: () => {
      const { messagesPools } = get();
      const one = messagesPools.shift();
      set(() => ({
        messagesPools
      }));
      return one;
    }
  }))
);

export default useGenerateStore;
export const useGenerateStoreSelectors = createSelectorFunctions(useGenerateStore);

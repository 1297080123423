import useTripStoreV4, { useTripStoreSelectorsV4 } from '@/stores/useTripStore_v4.js';
import { ZZUploadType } from '@/utils/const.js';
import styles from './hotels-panel.module.scss';
import dayjs from 'dayjs';
import { Button, Modal } from 'antd';
import { useGlobalStoreSelectors } from '@/stores/useGlobalStore.js';
import { deleteHotel } from '@/apis/trips_v4.js';
import notification from '@/utils/notification.jsx';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import toast from '@/utils/toast.js';
import { useGenerateStoreSelectors } from '@/stores/useGenerateStore.js';

function HotelsPanel({ className }) {
  const itinerary = useTripStoreSelectorsV4.use.itinerary();
  const toggleIsShowUpload = useGlobalStoreSelectors.use.toggleIsShowUpload();
  const [modal, contextHolder] = Modal.useModal();
  const fetchItineraryAndIntent = useTripStoreSelectorsV4.use.fetchItineraryAndIntent();
  const isReadOnly = useTripStoreSelectorsV4.use.isReadOnly();
  const isGenerating = useGenerateStoreSelectors.use.isGenerating();

  const getDates = item => {
    const formatDate = date => `${date.year}-${date.month}-${date.day}`;
    const checkin_day = dayjs(formatDate(item.check_in_time.date)).format('ddd, MMM D');
    const checkout_day = dayjs(formatDate(item.check_out_time.date)).format('ddd, MMM D');
    return checkin_day === checkout_day ? checkin_day : `${checkin_day} - ${checkout_day}`;
  };

  const onUpload = () => {
    if (isReadOnly) {
      toast.info('Please save as your own itinerary first.');
      return;
    }
    if (isGenerating) {
      toast.info('The itinerary is generating...');
      return;
    }
    toggleIsShowUpload(true, ZZUploadType.hotel, null);
  };

  const onConfirmDelete = flightId => {
    modal.confirm({
      centered: true,
      title: 'Delete Confirm',
      content: 'Are you sure to delete this hotel?',
      onOk: async close => {
        await onDelete(flightId);
        close();
      },
      onCancel: () => {
        console.log('cancel');
      }
    });
  };

  const onDelete = async hotelId => {
    const { sessionId, itineraryId } = useTripStoreV4.getState();
    await deleteHotel({
      session_id: sessionId,
      itinerary_id: itineraryId,
      hotel_id: hotelId
    });
    notification.success({
      message: 'Hotel deleted successfully'
    });
    fetchItineraryAndIntent();
  };

  const onEdit = hotel => {
    toggleIsShowUpload(true, ZZUploadType.hotel, hotel);
  };

  return (
    <div className={`py-2 !px-4 ${className}`}>
      <Button type="primary" onClick={onUpload} className={'mb-4'}>
        Upload Hotel
      </Button>
      <div className={'flex flex-col'}>
        {itinerary?.hotels?.map(item => {
          return (
            <div key={item.id} className={`${styles.item} relative flex flex-col md:flex-row`}>
              <div className={`w-full md:w-0 md:flex-grow flex flex-col md:border-r pr-7 md:pr-4`}>
                <div className={'flex items-center font-medium text-[14px] md:text-[16px]'}>
                  {item.name}, {item.city}
                </div>
                <span className={'text-[13px] my-1 md:my-3'}>{getDates(item)}</span>
                {/*<span className={'text-[13px]'}>$ {item.cost}</span>*/}
              </div>
              <div className={`flex flex-col w-[30%] md:pl-4 mt-2 md:mt-0`}>
                <span className={styles.label}>confirmation #</span>
                <span className={`${styles.notes} mb-1 md:mb-3`}>{item.confirmation_number || '--'}</span>
                {/*<span className={styles.label}>NOTES</span>*/}
                {/*<span className={styles.notes}>{item.notes || '--'}</span>*/}
              </div>
              <span className={'f-center absolute right-2 bottom-2'}>
                <Button type="text" className={`${styles.delete} mr-2`} onClick={() => onEdit(item)} icon={<EditOutlined />} />
                <Button type="text" className={`${styles.delete}`} onClick={() => onConfirmDelete(item.id)} icon={<DeleteOutlined />} />
              </span>
            </div>
          );
        })}
      </div>
      {contextHolder}
    </div>
  );
}

export default HotelsPanel;

import MapPanel from '@/components/home-v4/map-panel.jsx';
import ItineraryContainer from '@/components/home-v4/itinerary-elements/itinerary-container.jsx';
import styles from './left-panel.module.scss';
import IconMap from '../../assets/svgs/icon_map.svg?react';
import IconList from '../../assets/svgs/icon_list.svg?react';
import { useState } from 'react';
import { logEvent } from '@/utils/ga.js';

function LeftPanel() {
  const [activeTab, setActiveTab] = useState(1);

  const onSwitch = type => {
    setActiveTab(type);
    const name = `itinerary_tab_${type == 0 ? 'map' : 'list'}_click`;
    console.log('name', name);
    logEvent({ name });
  };

  return (
    <div className={'h-full overflow-y-auto relative'}>
      <span className={`${styles.tabs} absolute right-1/2 translate-x-1/2  top-2 md:top-3 md:right-4 md:translate-x-0`}>
        <span className={`${styles.tab} ${activeTab == 0 ? styles.active : ''}`} onClick={() => onSwitch(0)}>
          <IconMap className={'mr-1'} />
          Map View
        </span>
        <span className={`${styles.tab} ${activeTab == 1 ? styles.active : ''}`} onClick={() => onSwitch(1)}>
          <IconList className={'mr-1'} />
          List View
        </span>
      </span>
      <MapPanel className={`${activeTab == 0 ? '' : 'hidden'}`} />
      <ItineraryContainer className={`${activeTab == 1 ? '' : 'hidden'}`} />
    </div>
  );
}

export default LeftPanel;

import styles from './preview-modal.module.scss';
import { Button, Modal } from 'antd';
import { useTripStoreSelectorsV4 } from '@/stores/useTripStore_v4.js';
import { useUserStoreSelectors } from '@/stores/useUserStore.js';
import { formatItineraryDate } from '@/utils/index.js';
import * as htmlToImage from 'html-to-image';
import LogoImage from '@/assets/images/logo_v2.png';
import notification from '@/utils/notification.jsx';

function PreviewModal(props) {
  const itinerary = useTripStoreSelectorsV4.use.itinerary();
  const isLogin = useUserStoreSelectors.use.isLogin();
  const user = useUserStoreSelectors.use.user();

  const onDownload = async () => {
    const node = document.getElementById('share-itinerary-image');

    console.log('node', node);
    htmlToImage
      .toPng(node, { skipFonts: true, preferredFontFormat: 'Roboto' })
      .then(function(dataUrl) {
        const link = document.createElement('a');
        link.download = `${itinerary.daily_itineraries.length} Days-${itinerary.daily_itineraries.length - 1} Nights Itinerary.png`;
        link.href = dataUrl;
        link.click();
        notification.success({
          message: 'Download success.'
        });
        props.onCancel && props.onCancel();
      })
      .catch(function(error) {
        console.error('oops, something went wrong!', error);
      });
  };

  return (
    <Modal {...props} className={styles.previewModal} closeIcon={false} centered width={500} footer={null}>
      {!!itinerary && (
        <>
          <div className={`${styles.container}`}>
            <div className={`font-medium ${styles.content}`} id="share-itinerary-image">
              <div className="f-center mb-3">
                <img src={LogoImage} alt="" className={'w-24 h-24'} />
              </div>
              <div className={'text-center font-semibold text-[24px] leading-snug mt-3'}>{itinerary.title}</div>
              <div className={'mt-5 text-[13px] w-full f-center'}>
                {isLogin && (
                  <span className={'flex items-center mr-2 text-[#333]'}>
                    <img src={user?.extra?.avatar} alt="" className={'w-5 h-5 rounded-full'} />
                    <span className={'text-[13px] font-medium mx-1 whitespace-nowrap'}>{user?.extra?.name}'s</span>
                  </span>
                )}
                {itinerary?.daily_itineraries?.length} Days-{itinerary.daily_itineraries?.length - 1} Nights Itinerary
              </div>
              <div className={`${styles.dashed2} mt-[50px] mb-5`} />
              {itinerary?.daily_itineraries?.map((day, index) => {
                return (
                  <div key={day.id} className={`${styles.day}`}>
                    <div className={'text-[16px]'}>
                      <span>
                        Day {index + 1}
                        {day.date ? ':' : ''}
                      </span>
                      <span className={'ml-2'}>{day.date ? formatItineraryDate(day.date) : ''}</span>
                    </div>
                    <ul>
                      {day.places.map(place => {
                        return (
                          <li key={place.place_id} className={'my-2'}>
                            {place.name}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                );
              })}
            </div>
          </div>
          <Button shape={'round'} type={'primary'} className={'!absolute !left-1/2 !-translate-x-1/2 !bottom-3'} onClick={onDownload}>
            Download Image
          </Button>
        </>
      )}
    </Modal>
  );
}

export default PreviewModal;

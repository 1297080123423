import { memo, useCallback } from 'react';
import styles from './rich-message.module.scss';
import { PlayCircleFilled, PlusOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import { ZZEmittedEvent, ZZMessageType, ZZSourceType } from '@/utils/const.js';
import useSource from '@/hooks/useSource.js';
import { getImageUrl } from '@/utils/index.js';
import { get, isEmpty } from 'lodash-es';
import usePlaceV4 from '@/hooks/usePlace_v4.jsx';
import { useGlobalStoreSelectors } from '@/stores/useGlobalStore.js';
import { useResponsive } from 'ahooks';
import YoutubeCover from '@/components/commons/YoutubeCover/index.jsx';
import emitter from '@/utils/emitter.js';

function RichMessage({ message }) {
  const { onClickSource } = useSource();
  // const [parts, setParts] = useState([message.data]);
  // const prevMessageData = useRef('');
  // useEffect(() => {
  //   if (prevMessageData?.current !== message?.data) {
  //     prevMessageData.current = message?.data;
  //     const regex = /\[?[sS]ource:\s*([0-9]|[A-Z]-[^\]]*)]/g;
  //     // const regex = /\[(\d+)]/;
  //     // 分割字符串并用TestComponent替换匹配部分
  //     const parts = message?.data.split(regex).map((sourceId, index) => {
  //       if (index % 2 === 1) {
  //         // 返回自定义组件
  //         return (
  //           <SourceEmbed
  //             key={index}
  //             sourceId={sourceId}
  //             message={message}
  //             onClickSource={source => {
  //               onClick(source);
  //             }}
  //           />
  //         );
  //       }
  //       return (
  //         <span
  //           key={sourceId.slice(0, 10)}
  //           dangerouslySetInnerHTML={{
  //             __html: sourceId
  //           }}
  //         />
  //       );
  //     });
  //     setParts(parts);
  //   }
  // }, [message?.data]);

  const onClick = useCallback(source => {
    console.log('source-->', source);
    if (!source) {
      return;
    }
    onClickSource({ source });
  }, []);

  return (
    <div className={'max-w-full'}>
      {message.messages?.map(item => {
        return (
          <>
            {item.type === ZZMessageType.text && item.data}
            {item.type === ZZMessageType.video && <VideosPanel videos={item.data} />}
            {item.type === ZZMessageType.place && <SourceEmbedV2 data={item.data} isPlace={true} onClickSource={onClick} />}
            {item.type === ZZMessageType.search_source && <SourceEmbedV2 data={item.data} isSource={true} onClickSource={onClick} />}
          </>
        );
      })}
    </div>
  );
}

function SourceEmbedV2({ data, isPlace, isSource, onClickSource }) {
  const { md } = useResponsive();
  return (
    <Popover
      trigger={md ? 'hover' : 'contextMenu'}
      content={<PopoverContent data={data} isPlace={isPlace} onClickSource={onClickSource} />}
      overlayInnerStyle={{
        padding: 0,
        overflow: 'hidden'
      }}
    >
      <span
        className={`inline-flex items-center bg-[#f2f3f5] hover:bg-gray-200 px-1.5 text-[12px] rounded-[9px] cursor-pointer ${styles.embed} translate-y-[2px]`}
      >
        <SourceLogo type={isSource ? data?.type : data?.place_type} url={data?.url} />
        <span className={'mr-1 font-bold whitespace-nowrap'}>{isPlace ? data.name : isSource ? data.title : ''}</span>
      </span>
    </Popover>
  );
}

// function SourceEmbed({ sourceId, message, onClickSource }) {
//   const [source, setSource] = useState(null);
//   const { md } = useResponsive();
//
//   useEffect(() => {
//     if (message?.sources) {
//       const source = message.sources.find(s => s.source_id == sourceId);
//       setSource(source);
//     }
//   }, [sourceId, message]);
//
//   // const getIcon = () => {
//   //   if (source?.type === ZZSourceType.webpage) {
//   //     // return <GlobalOutlined />;
//   //   } else if (
//   //     source?.type === ZZSourceType.attraction ||
//   //     source?.type === ZZSourceType.city ||
//   //     source?.type === ZZSourceType.restaurant ||
//   //     source?.type === ZZSourceType.hotel
//   //   ) {
//   //     return <PlusCircleOutlined />;
//   //   } else {
//   //     return <PlayCircleOutlined />;
//   //   }
//   // };
//
//   const onOpenChange = v => {
//     if (v) {
//       console.log('source.type', source?.type);
//       if (source?.type == ZZSourceType.attraction) {
//         // 地图聚焦景点
//         const v = message.grounding_list.find(item => item.name == source.title);
//         if (v) {
//           console.log('focus attraction', v);
//           emitter.emit(ZZEmittedEvent.focusAttraction, v);
//         }
//       }
//     }
//   };
//
//   return (
//     <Popover
//       trigger={md ? 'hover' : 'contextMenu'}
//       onOpenChange={onOpenChange}
//       content={<PopoverContent source={source} message={message} onClickSource={onClickSource} />}
//       overlayInnerStyle={{
//         padding: 0,
//         overflow: 'hidden'
//       }}
//     >
//       <span
//         className={`inline-flex items-center bg-[#f2f3f5] hover:bg-gray-200 px-1.5 text-[12px] rounded-[9px] cursor-pointer ${styles.embed} translate-y-[2px]`}
//         onClick={() => {
//           onClickSource(source);
//         }}
//       >
//         <SourceLogo type={source?.type} url={source?.url} />
//         <span className={'mr-1 font-bold whitespace-nowrap'}>{sourceId.replace(/A-|C-|H-|R-/g, '')}</span>
//       </span>
//     </Popover>
//   );
// }

function PopoverContent({ data, isPlace, onClickSource }) {
  const { getSourceName } = useSource();

  const onClick = useCallback(() => {
    onClickSource(data);
  }, []);

  return isPlace ? (
    <AttractionContent place={data} />
  ) : (
    <div className={'flex flex-col max-w-[400px] min-w-[200px] p-3 cursor-pointer'}>
      <div className={'flex items-center font-medium text-[12px] text-[#777]'}>
        <SourceLogo type={data.type} url={data?.url} />
        <span>{getSourceName({ source: data })}</span>
      </div>
      <div className={`mt-1 text-[13px] font-medium ellipsis-1`} onClick={onClick}>
        {data.title}
      </div>
      <div className={`mt-1 text-[13px] ellipsis-4`} onClick={onClick}>
        {data.content}
      </div>
    </div>
  );
}

function AttractionContent({ place }) {
  console.log('place', place);
  const { onAddPlace } = usePlaceV4();
  const toggleIsShowPlaceInfo = useGlobalStoreSelectors.use.toggleIsShowPlaceInfo();

  const onClick = () => {
    toggleIsShowPlaceInfo(true, place.place_id);
  };

  return place ? (
    <div className={'w-[300px] relative cursor-pointer'} onClick={onClick}>
      <div onClick={e => e.stopPropagation()}>
        {/*<Carousel dots={false} arrows>*/}
        {/*  {get(place, 'photos', []).map(item => (*/}
        {/*    <div key={item.url}>*/}
        <img src={getImageUrl(get(place, 'photos.0.url', []), 450)} alt="" className={'h-[200px] w-full object-cover'} onClick={onClick} />
        {/*    </div>*/}
        {/*  ))}*/}
        {/*</Carousel>*/}
      </div>
      <span
        className={
          'absolute top-2 right-2 w-7 h-7 cursor-pointer rounded-full bg-[#f2f3f5] text-black hover:bg-black hover:text-white f-center border z-12'
        }
        onClick={e => onAddPlace(e, place)}
      >
        <PlusOutlined />
      </span>
      <div className={'p-2'}>
        <div className={'font-medium text-[15px] mb-2'}>{place.name}</div>
        <div className={'text-[14px] text-[#666] ellipsis-6'}>{place.description}</div>
      </div>
    </div>
  ) : null;
}

function SourceLogo({ type, url }) {
  let type2 = type || ZZSourceType.attraction;
  const maps = {
    [ZZSourceType.webpage]: (
      <svg className="icon w-4 h-4 mr-1.5" aria-hidden="true">
        <use xlinkHref="#icon-website"></use>
      </svg>
    ),
    [ZZSourceType.youtube]: (
      <svg className="icon w-4 h-4 mr-1.5" aria-hidden="true">
        <use xlinkHref="#icon-youtube1"></use>
      </svg>
    ),
    [ZZSourceType.reddit]: (
      <svg className="icon w-4 h-4 mr-1.5" aria-hidden="true">
        <use xlinkHref="#icon-reddit"></use>
      </svg>
    ),
    [ZZSourceType.hotel]: (
      <svg className="icon w-4 h-4 mr-1.5" aria-hidden="true">
        <use xlinkHref="#icon-hotel-line"></use>
      </svg>
    ),
    [ZZSourceType.restaurant]: (
      <svg className="icon w-4 h-4 mr-1.5" aria-hidden="true">
        <use xlinkHref="#icon-restaurant-15"></use>
      </svg>
    ),
    [ZZSourceType.city]: (
      <svg className="icon w-4 h-4 mr-1.5" aria-hidden="true">
        <use xlinkHref="#icon-basesettingCityCate"></use>
      </svg>
    ),
    [ZZSourceType.attraction]: (
      <svg className="icon w-4 h-4 mr-1.5" aria-hidden="true">
        <use xlinkHref="#icon-place"></use>
      </svg>
    )
  };
  let logo = maps[type2] || null;
  if (type2 === 'webpage' && !!url) {
    if (url?.includes('yelp')) {
      logo = (
        <svg className="icon w-4 h-4 mr-1.5 shrink-0" aria-hidden="true">
          <use xlinkHref="#icon-yelp"></use>
        </svg>
      );
    } else if (url?.includes('tripadvisor')) {
      logo = (
        <svg className="icon w-4 h-4 mr-1.5 shrink-0" aria-hidden="true">
          <use xlinkHref="#icon-tripadvisor"></use>
        </svg>
      );
    }
  }
  return logo;
}

function VideosPanel({ videos }) {
  const onPlay = video => {
    emitter.emit(ZZEmittedEvent.showPlayer, {
      title: video.title,
      url: video.link
    });
  };

  return !isEmpty(videos) ? (
    <div className={'flex items-center w-full overflow-x-auto py-2'}>
      {videos.map(video => {
        return (
          <div key={video.id} onClick={() => onPlay(video)} className={styles.videoItem}>
            <YoutubeCover mask item={video} className={'w-full min-w-[115px] h-full flex-shrink-0'} />
            <PlayCircleFilled className={'text-[24px] absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 text-white'} />
          </div>
        );
      })}
    </div>
  ) : null;
}

const RichMessageMemo = memo(
  RichMessage,
  (prev, next) => prev.message.messages === next.message.messages && prev.message.sources === next.message.sources
);

export default RichMessageMemo;

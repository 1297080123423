import { Col, Input, Modal, Row } from 'antd';
import { useState } from 'react';
import { nanoid } from 'nanoid';
import { CheckCircleOutlined } from '@ant-design/icons';
import { doFeedback } from '@/apis/trips_v4.js';
import useTripStoreV4 from '@/stores/useTripStore_v4.js';
import { isEmpty } from 'lodash-es';
import toast from '@/utils/toast.js';
import notification from '@/utils/notification.jsx';

function FeedbackModal({ onCancel, onFinished, options, ...props }) {
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([
    {
      id: nanoid(4),
      text: 'ℹ️ ️Inaccurate',
      selected: false
    },
    {
      id: nanoid(4),
      text: '🗓️  Out of date',
      selected: false
    },
    {
      id: nanoid(4),
      text: '⤵️  Too short',
      selected: false
    },
    {
      id: nanoid(4),
      text: '⤴️  Too long',
      selected: false
    },
    {
      id: nanoid(4),
      text: '😒  Harmful or offensive',
      selected: false
    },
    {
      id: nanoid(4),
      text: '❓Not helpful',
      selected: false
    }
  ]);

  const onSelectItem = o => {
    setList(
      list.map(item => {
        if (o.id === item.id) {
          return {
            ...item,
            selected: !o.selected
          };
        }
        return item;
      })
    );
  };

  const onSubmit = async () => {
    try {
      const { sessionId } = useTripStoreV4.getState();
      const reasons = list
        .filter(o => o.selected)
        .map(o => o.text)
        .concat(input)
        .filter(text => !!text);
      if (isEmpty(reasons)) {
        toast.info('Please select at least one reason.');
        return;
      }
      setIsLoading(true);
      const params = {
        session_id: sessionId,
        message: options.message,
        better_answer: options.origin,
        reason: reasons,
        event_type: 'thumbs_down'
      };
      await doFeedback(params);
      notification.success({
        message: 'Thanks for your feedback!'
      });
      onFinished(options.message);
      onCancel();
    } catch (e) {
      console.log('e', e);
    } finally {
      setIsLoading(false);
    }
  };

  const onAfterClose = () => {
    setList(
      list.map(item => ({
        ...item,
        selected: false
      }))
    );
    setInput('');
  };

  return (
    <Modal
      {...props}
      centered
      width={600}
      onOk={onSubmit}
      onCancel={onCancel}
      okText="Done"
      title="Help us imporove"
      afterClose={onAfterClose}
      okButtonProps={{
        loading: isLoading
      }}
    >
      <div className={'flex flex-col'}>
        <div className={'text-[14px] text-[#666]'}>Provide additional feedback on this answer. Select all that apply.</div>
        <Row gutter={12} className={'mt-3'}>
          {list.map(item => (
            <Col key={item.id} span={12} className={'mb-3'} onClick={() => onSelectItem(item)}>
              <div className={'w-full h-full p-2 border rounded-md flex justify-between items-center cursor-pointer'}>
                <span>{item.text}</span>
                {item.selected && <CheckCircleOutlined className={'text-black text-[16px]'} />}
              </div>
            </Col>
          ))}
        </Row>
        <div className={'mt-3'}>
          <div className="flex items-center mb-2">
            <span className={'mr-1'}>How can the response be improved?</span>
            <span className={'text-[13px] text-[#888]'}>(optional)</span>
          </div>
          <Input placeholder="Your feedback..." value={input} onChange={e => setInput(e.target.value)} />
        </div>
      </div>
    </Modal>
  );
}

export default FeedbackModal;

import { useState, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import styles from './place-message.module.scss';
import { Button } from 'antd';
import { LeftOutlined, PlusOutlined, RightOutlined } from '@ant-design/icons';
import { get } from 'lodash-es';
import { getImageUrl } from '@/utils/index.js';
import { useGlobalStoreSelectors } from '@/stores/useGlobalStore.js';
import RichMessage from '@/components/home-v4/chat-elements/rich-message.jsx';
import usePlaceV4 from '@/hooks/usePlace_v4.jsx';
import { useResponsive } from 'ahooks';

function PlaceMessage({ message }) {
  return (
    <div className={'w-full'}>
      <PlacePanels places={message.places} />
      <RichMessage message={message} />
    </div>
  );
}

function PlacePanels({ places }) {
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  const swiperRef = useRef(null);
  // const fetchItineraryAndIntent = useTripStoreSelectorsV4.use.fetchItineraryAndIntent();
  const toggleIsShowPlaceInfo = useGlobalStoreSelectors.use.toggleIsShowPlaceInfo();
  const { onAddPlace } = usePlaceV4();
  const { md } = useResponsive();

  const onSwiperChange = () => {
    if (swiperRef.current) {
      const swiperInstance = swiperRef.current.swiper;
      setIsBeginning(swiperInstance.isBeginning);
      setIsEnd(swiperInstance.isEnd);
    }
  };

  const onPrev = () => {
    if (swiperRef.current) {
      const swiperInstance = swiperRef.current.swiper;
      swiperInstance.slidePrev();
    }
  };

  const onNext = () => {
    if (swiperRef.current) {
      const swiperInstance = swiperRef.current.swiper;
      swiperInstance.slideNext();
    }
  };

  const onPlaceInfo = place => {
    toggleIsShowPlaceInfo(true, place.place_id);
  };

  return (
    <div className={'mb-3 flex flex-col'}>
      <Swiper
        ref={swiperRef}
        slidesPerView={md ? 4 : 2}
        slidesPerGroup={md ? 4 : 2}
        spaceBetween={20}
        speed={800}
        pagination={{
          clickable: true
        }}
        onSlideChange={onSwiperChange}
        className={`${styles.swiper}`}
      >
        {places.map(item => {
          return (
            <SwiperSlide key={get(item, 'place_id')}>
              <div className={'flex flex-col relative'} onClick={() => onPlaceInfo(item)}>
                <img src={getImageUrl(get(item, 'photos.0.url'), 400)} alt="" className={'w-full aspect-square object-cover rounded'} />
                <span className={'mt-2 font-medium text-[14px] text-left'}>{get(item, 'name')}</span>
                <span
                  className={
                    'absolute top-1 right-1 w-7 h-7 cursor-pointer rounded-full bg-[#f2f3f5] hover:bg-black hover:text-white text-black f-center border'
                  }
                  onClick={e => onAddPlace(e, item)}
                >
                  <PlusOutlined className={'text-[18px]'} />
                </span>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
      {md && (
        <div className="f-center mt-3">
          <Button size={'small'} disabled={isBeginning} shape="circle" icon={<LeftOutlined />} className="!bg-gray-500] mr-3" onClick={onPrev} />
          <Button size={'small'} disabled={isEnd} shape="circle" icon={<RightOutlined />} className="!bg-gray-500] ml-3" onClick={onNext} />
        </div>
      )}
    </div>
  );
}

export default PlaceMessage;

import styles from './attraction-item.module.scss';
import { Button, Tooltip } from 'antd';
import { useGlobalStoreSelectors } from '@/stores/useGlobalStore.js';
import { get, isEmpty } from 'lodash-es';
import { convertMinutesToHours, formatNumber, getImageUrl } from '@/utils/index.js';
import { useTripStoreSelectorsV4 } from '@/stores/useTripStore_v4.js';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import emitter from '@/utils/emitter.js';
import { ZZEmittedEvent } from '@/utils/const.js';
import { logEvent, tagEvent } from '@/utils/ga.js';
import IconStar from '../../../assets/svgs/icon_star.svg?react';
import IconDelete from '../../../assets/svgs/icon_delete.svg?react';
import IconDrag from '../../../assets/svgs/icon_drag.svg?react';
import IconMustGo from '../../../assets/svgs/icon_mustgo.svg?react';
import IconPlay from '../../../assets/svgs/icon_play.svg?react';
import { ClockCircleOutlined, EditOutlined } from '@ant-design/icons';
import toast from '@/utils/toast.js';
import { useGenerateStoreSelectors } from '@/stores/useGenerateStore.js';
import DeleteModal from '@/components/home-v4/modals/delete-modal.jsx';

function AttractionItem({ place, dayIndex, attractionIndex, onEditDuration, isShowFrom }) {
  const isReadOnly = useTripStoreSelectorsV4.use.isReadOnly();
  const toggleIsShowPlaceInfo = useGlobalStoreSelectors.use.toggleIsShowPlaceInfo();
  const toggleIsShowBooking = useGlobalStoreSelectors.use.toggleIsShowBooking();
  const deleteAttraction = useTripStoreSelectorsV4.use.deleteAttraction();
  const isGenerating = useGenerateStoreSelectors.use.isGenerating();
  const [youtubes, setYoutubes] = useState([]);
  const [isShowDelete, setIsShowDelete] = useState(false);

  useEffect(() => {
    init();
  }, []);

  const init = () => {
    setYoutubes(place?.references?.filter(item => item.source === 'youtube'));
  };

  const onBeforeDelete = e => {
    e?.stopPropagation();
    if (isReadOnly) {
      toast.info('Please save as your own itinerary first.');
      return;
    }
    if (isGenerating) {
      toast.info('The itinerary is generating...');
      return;
    }
    logEvent({
      name: 'itinerary_attraction_delete_before'
    });
    setIsShowDelete(true);
  };

  const onDeleteAttraction = () => {
    logEvent({
      name: 'itinerary_attraction_delete_confirm'
    });
    deleteAttraction(dayIndex, place);
  };

  const onDetails = () => {
    logEvent({
      name: 'itinerary_attraction_detail'
    });
    toggleIsShowPlaceInfo(true, place.place_id);
  };

  const getTourTimes = place => {
    if (place.start_time && place?.duration_minutes) {
      const start = dayjs()
        .hour(place.start_time.hour)
        .minute(place.start_time.minute)
        .second(0);
      const end = start.add(place.duration_minutes, 'minute');
      return `${start.format('hh:mm A')} - ${end.format('hh:mm A')}`;
    } else if (place?.duration_minutes) {
      return convertMinutesToHours(place?.duration_minutes);
    } else {
      return null;
    }
  };

  const onBooking = e => {
    e.stopPropagation();
    logEvent({
      name: 'itinerary_booking_click'
    });
    tagEvent('event', 'conversion', {
      send_to: 'AW-16706051350/ymseCLKk8dcZEJa6iJ4-'
    });
    toggleIsShowBooking(true, {
      place_id: place.place_id,
      booking_url: place?.booking_url
    });
  };

  const onPlayYoutube = (source, e) => {
    e?.stopPropagation();
    logEvent({
      name: 'itinerary_YouTube_click'
    });
    emitter.emit(ZZEmittedEvent.showPlayer, {
      title: `The youtube video about ${place?.name}`,
      url: source.url,
      type: source.reference_type
    });
  };

  const onPlayImage = e => {
    e.stopPropagation();
    if (isEmpty(youtubes)) {
      onDetails();
    } else {
      onPlayYoutube(youtubes[0], e);
    }
  };

  return (
    <div className={`${styles.attractionItem} relative`} onClick={onDetails}>
      <div className={'flex flex-col items-center mr-2'}>
        <span className={`w-[1px] flex-1 flex flex-col items-center ${attractionIndex == 0 && !isShowFrom ? 'invisible' : ''}`}>
          <span className="w-1.5 h-1.5 rounded-full bg-[#6B7280]" />
          <span className={'w-0 flex-grow'} style={{ borderRight: '1px dashed #6B7280' }} />
        </span>
        <span className={'w-6 h-6 my-1 rounded-full text-white f-center flex-shrink-0'} style={{ backgroundColor: place.color }}>
          {attractionIndex + 1}
        </span>
        <span className={`w-[1px] flex-1 flex flex-col items-center`}>
          <span className={'w-0 flex-grow'} style={{ borderRight: '1px dashed #6B7280' }} />
          <span className="w-1.5 h-1.5 rounded-full bg-[#6B7280]" />
        </span>
      </div>
      <IconDrag className={'self-center mr-1'} />
      <div className={'flex flex-col md:flex-row w-0 flex-grow'}>
        <div
          className={'relative md:mr-3 shrink-0 mb-2 md:mb-0 rounded-md md:rounded-[12px] overflow-hidden border border-[#D3D3D3]'}
          onClick={e => onPlayImage(e)}
        >
          {!isEmpty(youtubes) && (
            <div className={`f-center absolute inset-0 ${styles.mask}`}>
              <IconPlay class={'absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2'} />
            </div>
          )}
          <img src={getImageUrl(get(place, 'photos.0.url'), 400)} alt="" className={'w-full h-[180px] md:w-60 md:h-[180px] object-cover'} />
        </div>
        <div className={'flex-1 md:w-0 flex flex-col justify-around relative'}>
          <span
            className={`absolute right-3 top-0 cursor-pointer ${styles.btns} md:hidden`}
            onClick={e => {
              e.stopPropagation();
            }}
          >
            <IconDelete onClick={onBeforeDelete} className={'w-7 md:w-auto'} />
          </span>
          {place?.duration_minutes && (
            <div
              className={'text-[14px] mb-1'}
              onClick={e => {
                e.stopPropagation();
                if (isReadOnly) {
                  toast.info('Please save as your own itinerary first.');
                  return;
                }
                onEditDuration();
              }}
            >
              <ClockCircleOutlined className={'mr-2'} />
              {getTourTimes(place)} <EditOutlined className={`ml-1`} />
            </div>
          )}
          <div className={'font-medium text-[20px] text-[#374151] ellipsis-1 pr-9 flex items-center'}>
            {place?.duration_reason && (
              <Tooltip title={<div className={'text-[#444] font-medium text-[13px]'}>{place?.duration_reason}</div>} color={'white'}>
                <IconMustGo className={'mr-2'} />
              </Tooltip>
            )}
            <span>{place?.name}</span>
          </div>
          <div className={'w-10 h-[1px] bg-[#E5E7EB] my-1.5'} />
          <div className={'text-[14px] text-[#6B7280] ellipsis-3'}>{place?.description}</div>
          <div className={'w-10 h-[1px] bg-[#E5E7EB] my-1.5'} />
          <div className="flex items-end justify-between">
            <span className={'f-center'}>
              <span className={'font-medium'}>{place?.user_rating}</span>
              <IconStar className={'ml-0.5 mr-1 mb-0.5'} />
              <span className={'text-[#374151]'}>({formatNumber(place?.total_user_reviews)} reviews)</span>
            </span>
            {!!place?.booking_url && <Button onClick={onBooking}>See Tickets</Button>}
          </div>
        </div>
      </div>
      <DeleteModal open={isShowDelete} onCancel={() => setIsShowDelete(false)} onSubmit={onDeleteAttraction} />
    </div>
  );
}

export default AttractionItem;

import { useLocation, useNavigate } from 'react-router-dom';
import UserEntryV2 from '@/components/commons/UserEntryV2/index.jsx';

function Header({ className }) {
  const navigate = useNavigate();
  const location = useLocation();

  const onAdmin = () => {
    navigate(`/`, {
      replace: true
    });
  };

  return (
    <header
      className={`w-full flex justify-between items-center ${
        location.pathname == '/' ? 'text-white' : 'text-[#313131]'
      }  z-20 py-3 px-3 md:px-5 ${className}`}
    >
      <span className="f-center">
        <img src="https://storage.googleapis.com/solaris_melange/resources/logo_v2.png" alt="" className="w-8 mr-1 rounded-full" />
        <span className="cursor-pointer z-10 text-[14px] font-medium" onClick={onAdmin}>
          <span className={`font-medium text-[16px] mr-3 ${location.pathname == '/' ? 'text-white' : 'text-[#DE3151]'}`}>SnapTrip</span> Travel
          planner
        </span>
      </span>
      <UserEntryV2 />
    </header>
  );
}

export default Header;
